import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useRef,
} from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  redirect,
} from "react-router-dom";
// import { setKey } from "react-geocode";
import * as api from "./apis/FusionAPI";

import Sidebar from "./components/Sidebar";
import Header from "./components/Header";

import Dashboard from "./pages/Dashboard";
import CreateAlert from "./pages/CreateAlert";
import ViewAlerts from "./pages/ViewAlerts";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";

import ResponderDashboard from "./pages/Responder/ResponderDashboard";
import FullMap from "./pages/Responder/FullMap";
import Incidents from "./pages/Responder/Incidents";
import Employees from "./pages/Responder/Employees";
import Apparatus from "./pages/Responder/Apparatus";
import DispatcherChat from "./pages/Responder/DispatcherChat";

import PublicUserDashboard from "./pages/Weather/PublicUserDashboard";
import Alerts from "./pages/Weather/Alerts";
import Instances from "./pages/Weather/Instances";
import WeatherForecast from "./pages/Weather/WeatherForecast";
import Maps from "./pages/Weather/Maps";
import StormCenter from "./pages/Weather/StormCenter";
import MarineForecast from "./pages/Weather/MarineForecast";
import SpecialReports from "./pages/Weather/SpecialReports";
import Charts from "./pages/Weather/Charts";

import SystemDashboard from "./pages/System/SystemDashboard";
import Connections from "./pages/System/Connections";
import ManageSocials from "./pages/System/ManageSocials";
import Help from "./pages/System/Help";
import Settings from "./pages/System/Settings";

import ActivateAccount from "./pages/ActivateAccount";
import CreateUser from "./pages/Admin/CreateUser";

import RoleManager from "./pages/Admin/RoleManager";
import CreateRole from "./pages/Admin/CreateRole";
import AccessibilitySettings from "./pages/AccessibilitySettings";

import EditUser from "./pages/Admin/EditUser";
import EditRole from "./pages/Admin/EditRole";

import GetWidgetData from "./data/widgetData";
import GlobalActions from "pages/System/GlobalActions";
import Cookies from "js-cookie";
import AlertCreator from "components/AlertCreator/AlertCreator";
import { GetRouteData } from "data/routeData";
import ResetPassword from "pages/ResetPassword";
import { ForgotPassword } from "pages/ForgotPassword";
import UserMenu from "pages/UserMenu";
import AlarmListener from "components/AlarmListener";
import { PrivacyPopup } from "pages/PrivacyPopup";
import { usePerms } from "hooks/usePerms";
import { Page404 } from "pages/404";
import TermsAndConditions from "pages/TermsAndConditions";
import PolicyAndCookies from "pages/PolicyAndCookies";

import { ConfigProvider } from "antd";
import ThemeSelectorTool from "components/ThemeSelectorTool";
import { clearCookies } from "utils/cookieUtils";

import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import Redirect from "pages/Redirect";

const configuration = {
  auth: {
    clientId: "fc3e525d-dc3e-4aaf-a892-ab5d48134bfe",
  },
};

const pca = new PublicClientApplication(configuration);

export const SidebarContext = createContext(true);
export const ThemesListContext = createContext([]);

const baseSize = 1000;
export const LayoutSizeContext = createContext({
  width: baseSize,
  height: window.innerHeight - 137,
  widgetWidth: baseSize / 3,
  doubleWidgetWidth: (baseSize / 3) * 2 + 9,
  widgetHeight: baseSize / 3,
  doubleWidgetHeight: baseSize / 3 + 100,
  scaleMult: 1,
  baseFontSize: 14,
});

//how many colors do we need?
//are there any colors that serve a specific purpose (ie. main background color (that 'dark-grey' color behind all of the pages/widgets))?
export const UniqueWidgetRefreshContext = createContext({
  base: 0,
  primary: 0,
  secondary: 0,
  tertiary: 0,

  //specialized ones
  fontColor: "white",
});

export const SetUniqueWidgetRefreshContext = createContext(() => {});

export const ColorContext = createContext({});
export const SetColorContext = createContext(() => {});

function App() {

  //universal color context
  const themes = [
    {
      name: "Default",
      value: {
        //main colors
        background: "#0b1826", //darkblue bg, main web page backgrond
        base: "#222a2d", //component background
        primaryShadow: "#1e374f", //dark blue, header, sidebar
        primary: "#325277",
        primaryHighlight: "#436c9a",
        fontColor: "#FFF",
      },
    },
    {
      name: "Greyscale",
      value: {
        background: "#000000", //darkblue bg, main web page backgrond
        base: "#111111", //component background
        primaryShadow: "#222222", //dark blue, header, sidebar
        primary: "#333333",
        primaryHighlight: "#444444",
        fontColor: "#FFF",
      },
    },
    {
      name: "High Contrast",
      value: {
        background: "#000", //darkblue bg, main web page backgrond
        base: "#FFFFFF", //component background
        primaryShadow: "#00FF00", //dark blue, header, sidebar
        primary: "#FFFF00",
        primaryHighlight: "#00FFFF",
        fontColor: "#000",
      },
    },
    {
      name: "Light Mode",
      value: {
        background: "#f5f5f5", //darkblue bg, main web page backgrond
        base: "#FFFFFF", //component background
        primaryShadow: "#e0e0e0", //dark blue, header, sidebar
        primary: "#d3d3d3",
        primaryHighlight: "#c0c0c0",
        fontColor: "#000",
      },
    },
  ];

  const [colors, setColors] = useState({
    ...themes[0].value,

    //bonus
    // warning: "#ffa500",
    // error: "#ff0000",
    // success: "#008000",
    // disabled: "#55585c", //input field disabled light grey
  });

  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [showFull, setShowFull] = useState(
    !process.env.NODE_ENV === "development" || true
  );

  const [updateSidebar, setUpdateSidebar] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [routeName, setRouteName] = useState({
    name: "dashboard",
    displayName: "Home Dashboard",
  });

  const [isAuthenticated, setIsAuthenticated] = useState(null);

  const [accessLevel, setAccessLevel] = useState(-1);

  const [search, setSearch] = useState("");

  const [widgetRefresh, setWidgetRefresh] = useState("");
  const [cookiesAgreed, setCookiesAgreed] = useState(false);
  const [privacyAgreed, setPrivacyAgreed] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [newTermsFullyAgreed, setNewTermsFullyAgreed] = useState(false);

  const [termsFullyViewed, setTermsFullyViewed] = useState(false);

  const [termsData, setTermsData] = useState({
    version: "",
    page: "",
  });

  const [policyData, setPolicyData] = useState({
    version: "",
    page: "",
  });

  useEffect(() => {
    api.getTermsAndConditions().then((data) => {
      setTermsData(data.data);
    });
    api.getPolicyAndCookies().then((data) => {
      setPolicyData(data.data);
    });
  }, []);

  const perms = usePerms();
  useEffect(() => {
    if (!isAuthenticated) {
      Cookies.remove("perms");
      Cookies.remove("accessLevel");
      return;
    }
    try {
      perms.get();
    } catch (e) {
      
    }
  }, [isAuthenticated]);

  useEffect(() => {
    authenticate();
  }, []);

  const authenticate = async () => {
    const isAuth =
      Cookies.get("accessToken") !== undefined &&
      Cookies.get("accessToken") !== null;
    setIsAuthenticated(isAuth);
    if (isAuth) {
      api
        .getAccessLevel()
        .then((res) => {
          if (res.status === 200) {
            perms.setAccessLevel(res.data.result.group_id);
            setAccessLevel(res.data.result.group_id);
          }
        })
        .catch((e) => {
          setIsAuthenticated(false);
        });
    }
  };

  const updateAuthenticated = (value) => {
    setIsAuthenticated(value);

    if (!value) return;

    api
      .getAccessLevel()
      .then((accessLevelRes) => {
        if (accessLevelRes.status === 200) {
          perms.setAccessLevel(accessLevelRes.data.result.group_id);
          setAccessLevel(accessLevelRes.data.result.group_id);
        }
      })
      .then(() => {
        //get permissions
        perms.get();
      });
  };

  const divStyle = {
    color: colors.fontColor,
    width: "100%",
  };
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    const cookie = perms.get();
    if (!cookie || !isAuthenticated) {
      return;
    }
    let allowed = [];

    const appdata = [...GetRouteData(perms, widgetRefresh)];

    appdata.forEach((item) => {
      if (item.permission()) {
        item.options.forEach((option) => {
          //set enabled by perm
          if (option.permission()) {
            allowed.push(option);
          }
          //set active based on location
        });
      }
    });
    setRoutes(allowed);
  }, [perms.sData, updateSidebar]);

  useEffect(() => {
    perms.reset();
    if (isAuthenticated) setShowFull(false);
  }, [isAuthenticated]);

  const initialWidth = Math.max(
    window.innerWidth - (sidebarOpen ? 295 : 100),
    1000
  );
  const [layoutObject, setLayoutObject] = useState({
    width: initialWidth,
    height: window.innerHeight - 137,
    widgetWidth: initialWidth / 3,
    doubleWidgetWidth: (initialWidth / 3) * 2 + 9,
    widgetHeight: initialWidth / 3,
    doubleWidgetHeight: initialWidth / 3 + 100,
    scaleMult: 1,
    baseFontSize: 14,
  });

  const [uwfValues, setUwfValues] = useState({
    base: 0,
  });

  useEffect(() => {
    const handleResize = () => {
      
      const newSize = Math.max(
        window.innerWidth - (sidebarOpen ? 295 : 100),
        1000
      );
      
      const newSizeHeight = window.innerHeight - 107;
      
      setLayoutObject({
        width: newSize * 0.9875,
        height: newSizeHeight,
        widgetWidth: newSize / 3,
        doubleWidgetWidth: (newSize / 3) * 2 + 9,
        widgetHeight: newSize / 3,
        doubleWidgetHeight: (newSize / 3) * 2 + 9,
        scaleMult: newSize / baseSize,
        baseFontSize: Math.max(((16 * newSize) / baseSize) * 0.75, 14),
      });
    };
    window.addEventListener("resize", handleResize);

    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [sidebarOpen, window.innerWidth, window.innerHeight]);

  return (
    <MsalProvider instance={pca}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: colors.primaryShadow,
          },
        }}
      >
        <SidebarContext.Provider value={sidebarOpen}>
          <LayoutSizeContext.Provider value={layoutObject}>
            <UniqueWidgetRefreshContext.Provider value={uwfValues}>
              <SetUniqueWidgetRefreshContext.Provider value={setUwfValues}>
                <ColorContext.Provider value={colors}>
                  <SetColorContext.Provider value={setColors}>
                    <ThemesListContext.Provider value={themes}>
                      <Router>
                        <div
                          // className="appGradient"
                          // className="dark"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            backgroundColor: colors.background,
                            backgroundSize: "cover",
                            backgroundRepeat: "repeat",
                            // minWidth: "100vw",
                            width: "100vw",
                            height: "100vh",
                            flex: 1,
                            flexGrow: 1,
                            // overflow: "hidden",
                          }}
                        >
                          {
                            <div
                              className="screenTooSmall"
                              style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                // opacity: (process.env.NODE_ENV === "development" ? .1 : 1),

                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100vh",
                                width: "100vw",
                                color: colors.fontColor,
                                backgroundColor: colors.background,
                                zIndex: 1300,
                              }}
                            >
                              <div>
                                <h1>Device Size Unsupported</h1>
                                <p>
                                  Please increase the size of your browser
                                  window to at least 1240px wide.
                                </p>
                              </div>
                            </div>
                          }
                          {/* {isAuthenticated &&
                          process.env.NODE_ENV === "development" && (
                            <ThemeSelectorTool />
                          )} */}
                          {perms.testPermission(
                            [8, 10, 11, 12, 13],
                            ["PendingAlarms"]
                          ) &&
                            isAuthenticated && (
                              <AlarmListener
                                isAuthenticated={isAuthenticated}
                              />
                            )}
                          <Sidebar
                            onOpenChanged={setSidebarOpen}
                            routeName={routeName}
                            accessLevel={accessLevel}
                            isAuthenticated={isAuthenticated}

                            updateSidebar={updateSidebar}
                            // userList={userWidgetData}
                            definedWidgetData={GetWidgetData(
                              widgetRefresh,
                              setWidgetRefresh,
                              sidebarOpen
                            )}
                          />


                          <div
                            style={{
                              width: "100%",
                              overflowY: "auto",
                              overflowX: "hidden",
                            }}
                          >
                            <PrivacyPopup
                              setShowFull={setShowFull}
                              showFull={showFull}
                              isAuthenticated={isAuthenticated}
                              acceptAction={() => {
                                setCookiesAgreed(true);
                                setPrivacyAgreed(true);

                                // if (isAuthenticated) api.updatePrivacyPolicy(true).then((res) => {
                                //   console.log("Privacy Policy Updated:", res);

                                // });
                              }}
                              declineAction={() => {
                                //logout
                                if (isAuthenticated) api.logout();
                                setCookiesAgreed(false);
                                setPrivacyAgreed(false);
                                setNewTermsFullyAgreed(false);
                                setIsAuthenticated(false);
                                // setShowFull(true)

                                // if (isAuthenticated) api.updatePrivacyPolicy();

                                //clear cookies
                                Cookies.remove("email");
                                Cookies.remove("rememberMe");
                              }}
                            />
                            {isAuthenticated && (
                              <Header
                                routeName={routeName}
                                isAuthenticated={isAuthenticated}
                                // sidebarOpen={sidebarOpen}
                                widgetRefresh={widgetRefresh}
                                setWidgetRefresh={setWidgetRefresh}
                                setIsAuthenticated={(value) =>
                                  updateAuthenticated(value)
                                }
                                setShowFull={setShowFull}
                                setNewTermsFullyAgreed={setNewTermsFullyAgreed}
                                setTermsFullyViewed={setTermsFullyViewed}
                              />
                            )}
                            <div
                              style={{
                                width: "calc(100% - 30px)",
                                // height: layoutObject.height - 30,
                                height: "calc(100% - 137px)",
                                color: colors.fontColor,
                                margin: 15,
                                display: "flex",
                              }}
                            >
                              <Routes>
                                {/* Redirect to login */}
                                {isAuthenticated === false && (
                                  <>
                                    <Route
                                      path="*"
                                      element={<Redirect route={"/"} />}
                                    />
                                    <Route
                                      path="/"
                                      element={
                                        <Signin
                                          setAuthenticated={(value) =>
                                            updateAuthenticated(value)
                                          }
                                          widgetRefresh={widgetRefresh}
                                          setCookiesAgreed={setCookiesAgreed}
                                          setPrivacyAgreed={setPrivacyAgreed}
                                          setTermsAgreed={setTermsAgreed}
                                          setNewTermsFullyAgreed={
                                            setNewTermsFullyAgreed
                                          }
                                          newTermsFullyAgreed={
                                            newTermsFullyAgreed
                                          }
                                          cookiesAgreed={cookiesAgreed}
                                          privacyAgreed={privacyAgreed}
                                          termsAgreed={termsAgreed}
                                          termsVersion={termsData.version}
                                          policyVersion={policyData.version}
                                          termsDate={termsData.date}
                                          terms={termsData.page}
                                          termsFullyViewed={termsFullyViewed}
                                          setTermsFullyViewed={
                                            setTermsFullyViewed
                                          }
                                        />
                                      }
                                    />
                                    <Route
                                      path="/activate"
                                      element={
                                        <PageContentWrapper>
                                          <ActivateAccount />
                                        </PageContentWrapper>
                                      }
                                    />
                                    <Route
                                      path="/resetPassword"
                                      element={
                                        <PageContentWrapper>
                                          <ResetPassword />
                                        </PageContentWrapper>
                                      }
                                    />
                                    <Route
                                      path="/forgotPassword"
                                      element={
                                        <PageContentWrapper>
                                          <ForgotPassword />
                                        </PageContentWrapper>
                                      }
                                    />
                                    <Route
                                      path="/terms"
                                      element={
                                        <PageContentWrapper>
                                          <TermsAndConditions
                                            data={termsData}
                                            setShowFull={setShowFull}
                                          />
                                        </PageContentWrapper>
                                      }
                                    />
                                    <Route
                                      path="/policy"
                                      element={
                                        <PageContentWrapper>
                                          <PolicyAndCookies
                                            data={policyData}
                                            setShowFull={setShowFull}
                                          />
                                        </PageContentWrapper>
                                      }
                                    />
                                  </>
                                )}
                                {isAuthenticated && (
                                  <>
                                    <Route
                                      path="/"
                                      element={
                                        <Redirect route={"/dashboard"} />
                                      }
                                    />
                                    <Route
                                      path="/activate"
                                      element={
                                        <PageContentWrapper>
                                          <ActivateAccount />
                                        </PageContentWrapper>
                                      }
                                    />
                                    <Route
                                      path="/accessibility"
                                      element={
                                        <PageContentWrapper>
                                          <AccessibilitySettings />
                                        </PageContentWrapper>
                                      }
                                    />
                                    <Route
                                      path="/register"
                                      element={
                                        <PageContentWrapper>
                                          <Signup />
                                        </PageContentWrapper>
                                      }
                                    />
                                    <Route
                                      path="/dashboard"
                                      element={
                                        <div
                                          style={{
                                            ...divStyle,
                                            // boxSizing: "border-box",
                                            // height: layoutObject.height,
                                            // width: "100%",
                                            overflow: "auto",
                                          }}
                                          className="customScrollbar-X  customScrollbar-Y"
                                          // className="scrollbar-hidden"
                                        >
                                          <Dashboard
                                            dimensions={dimensions}
                                            sidebarOpen={sidebarOpen}
                                            search={search}
                                            definedWidgetData={GetWidgetData(
                                              widgetRefresh,
                                              setWidgetRefresh,
                                              sidebarOpen
                                            )}
                                            accessLevel={accessLevel}
                                          />
                                        </div>
                                      }
                                    />
                                    <Route
                                      path="/terms"
                                      element={
                                        <PageContentWrapper>
                                          <TermsAndConditions
                                            data={termsData}
                                          />
                                        </PageContentWrapper>
                                      }
                                    />
                                    <Route
                                      path="/policy"
                                      element={
                                        <PageContentWrapper>
                                          <PolicyAndCookies data={policyData} />
                                        </PageContentWrapper>
                                      }
                                    />
                                    {routes.length > 0 && (
                                      <>
                                        <Route
                                          path="*"
                                          element={<Navigate to="/404" />}
                                        />
                                        <Route
                                          path="/404"
                                          element={<Page404 />}
                                        />
                                      </>
                                    )}
                                    {routes.length > 0 &&
                                      routes.map((route, index) => (
                                        <Route
                                          key={index}
                                          path={route.href}
                                          element={
                                            <PageContentWrapper>
                                              {route.page}
                                            </PageContentWrapper>
                                          }
                                        />
                                      ))}
                                  </>
                                )}
                              </Routes>
                            </div>
                          </div>
                        </div>
                      </Router>
                    </ThemesListContext.Provider>
                  </SetColorContext.Provider>
                </ColorContext.Provider>
              </SetUniqueWidgetRefreshContext.Provider>
            </UniqueWidgetRefreshContext.Provider>
          </LayoutSizeContext.Provider>
        </SidebarContext.Provider>
      </ConfigProvider>
    </MsalProvider>
  );
}

export default App;

const PageContentWrapper = ({ children }) => {
  const theme = useContext(ColorContext);
  return (
    <div
      className="page-content customScrollbar-X customScrollbar-Y"
      style={{ height: "100%", background: theme.base }}
    >
      {children}
    </div>
  );
};
