import { Slider } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { capitalizeWord } from "utils/stringUtils";
import { alertColorScale, getSeverityLevel } from "utils/alertUtils";
import DevicesMap from "pages/System/DevicesMap";
import RadialAlertMap from "./RadialAlertMap";
import AlertIcons from "components/AlertIcons";
import { ColorContext } from "App";

const DeviceAction = ({ deviceList, setData, data, setError }) => {
  useEffect(() => {
    if (
      data?.nodeId === undefined ||
      data?.deviceType === undefined ||
      data?.selectedDevice === undefined ||
      data?.selectedFunction === undefined
    ) {
      setData({
        nodeId: "",
        deviceType: "",
        selectedDevice: "",
        selectedFunction: "",
        headline: "",
        description: "",
        type: "",
        radius: 250,
        severity: 100,
        error: true,
      });
    }
  }, []);
  const theme = useContext(ColorContext);

  const updateData = (item) => {
    setData({
      ...data,
      ...item,
      error: verifyData({ ...data, ...item }),
    });
  };
  const AWAREmap = {
    depth1: "Depth",
    baro: "Pressure",
    lat: "Latitude",
    lon: "Longitude",
    elev: "Elevation",
    samp: "Device Sampling mode",
    mode: "Device Reporting Mode",
    calibration: "Sensor Calibration Offset Value",
    rssi: "Signal Strength",
    hex: "Raw Data (N/A)",
    IMEI: "IMEI number (Non-Unique)",
    NI: "Node Indicator",
    battery: "Battery Percentage",
    ffi1: "Flash Flood Indicator",
    airTemp: "Air Temperature",
    // 'ffThreshold': 'Device Threshold (Water Level Rise)',
    ffTheshold: "Device Threshold (Water Level Rise)",
    h2oTemp: "Water Temperature",
    calType: "Device Calibration Type (N/A)",
    dDetImgEnable: "Depth detection Imaging (Enabled/Disabled)",
    depthDet1: "Depth Detection level 1",
    depthDet2: "Depth Detection level 2",
    depthDet3: "Depth Detection level 3",
    depthHoldHours: "Depth Duration (Hours)",
    depthInd: "Depth Detection Indicator (Threshold Met)",
    dRateImgEnable: "Drop Rate Imaging (Enabled/Disabled)",
    drInd: "Drop Rate Indicator (Threshold Met)",
    dropThresh: "Drop Rate Threshold",
    dropSDI: "Device Drop Count (SDI-12) Tipping bucket",
    ffiImgEnable: "Flash Flood Detection Imaging (Enabled/Disabled)",
    firstPkt: "First Packet Indicator (True/False)",
    gatewayType: "Network Type (Satellite/Iridium)",
    gpsSync: "GPS Sync Success Indicator (Unsuccessful/successful)",
    hResImgEnable: "High-Resolution Imaging (Enabled/Disabled)",
    imgHoldStart: "Triggered Imaging Hold-Off Start",
    imgHoldEnd: "Triggered Imaging Hold-Off End",
    intensity: "Rain Intensity",
    ipAddress: "IP Address (Manually Set)",
    port: "Port (Manually Set)",
    pCommand: "Last Command Sent",
    protocol: "Last Packet Protocol (N/A)",
    rainfall: "Rain Fall Amount (Tipping Bucket)",
    soilSDI: "Soil Moisture Reading (SDI-12)",
    soilTempSDI: "Soil Temperature Reading (SDI-12)",
    saltwater: "Saltwater Adjustment (Enabled/Disabled)",
    tips: "Number of Bucket Tips (Tipping Bucket)",
    pict: "Latest Picture Name",
  };

  const verifyData = (data) => {
    
    let error = false;
    if (
      data.nodeId === "" ||
      data.deviceType === "" ||
      data.selectedFunction === ""
    ) {
      error = true;
    }
    if ( data.selectedFunction === "Create Radial Alert"){
      if (data.headline === "" || data.description === "" || data.type === "" || data.radius === "" || data.severity === "") {
        error = true;
        setError({ message: "Action incomplete, check for missing fields." });
      }
    }
    
  if (!error) {
    setError(null)
  }
    return error;
  };
  const deviceTypes = [...new Set(deviceList.map((device) => device.type))];

  const functionList = {
    "AWARE v5": ["Create Radial Alert"],
  };

  const deviceFunctions = {
    "Create Radial Alert": {
      deviceId: (
        <div style={{padding: 5}}>
          Device ID: <select
            style={{
              background: theme.base,
              padding: 5,
            }}
            onChange={(e) => {
              // find device by nodeId
              const device = deviceList.find((device) => {
                return device.nodeId.toString() === e.target.value;
              });
              if (device) updateData({
                nodeId: e.target.value,
                selectedDevice: device,
                coordinates: [device.lat, device.lon],
              });
            }}
            value={data.nodeId}
          >
            <option value="">-Select-</option>
            {deviceList.map((device) => {
              if (device.type === data.deviceType)
                return <option value={device.nodeId}>{device.nodeId}</option>;
            })}
          </select>
        </div>
      ),
      headline: "",
      description: "",
      type: "",
      radius: 250,
      severity: 100,
    },
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div
        style={{
          padding: 20,
          borderRadius: 10,
          margin: 10,
          background: theme.primary,
          display: "flex",
          flexDirection: "row",
          gap: 20,
          alignItems: "center",
        }}
      >
        Device:
        <select
          style={{
            background: theme.base,
            padding: 5,
          }}
          onChange={(e) => {
            
            // let device = deviceList.find((device) => device.type === e.target.value)
            if (e.target.value === "") {
              updateData({ deviceType: "" });
              return;
            } else
              updateData({
                deviceType: e.target.value,
                // coordinates: [device.lat, device.lon]
              });
          }}
          value={data.deviceType}
        >
          <option value="">-Select-</option>
          {deviceTypes.map((type) => {
            return <option value={type}>{type}</option>;
          })}
        </select>
        {data.deviceType && (
          <select
            style={{
              background: theme.base,
              padding: 5,
            }}
            onChange={(e) => {
              updateData({ selectedFunction: e.target.value });
            }}
            value={data.selectedFunction}
          >
            <option value="">-Select-</option>
            {functionList[data.deviceType].map((func) => {
              return <option value={func}>{func}</option>;
            })}
          </select>
        )}
      </div>
      {data.selectedFunction && deviceFunctions[data.selectedFunction] && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: 20,
            borderRadius: 10,
            margin: 10,
            background: theme.primary,
            display: "flex",
            flexDirection: "row",
            gap: 20,
          }}
        >
          <div style={{width: 400, height: 400}}>
            
            <RadialAlertMap arrayOfDevices={deviceList} selectedDevice={data.selectedDevice} setSelectedDevice={(e) => {
              
              const device = deviceList.find((device) => {
                return device.nodeId === e;
              });
              
              if (device) updateData({
                nodeId: e,
                selectedDevice: device,
                coordinates: [device.lat, device.lon],
              });
            }}radius={data.radius} />
          </div>
          <div>
          {Object.keys(deviceFunctions[data.selectedFunction]).map((key) => {
            if (key === "deviceId")
              return deviceFunctions[data.selectedFunction][key];
            if (key === "description")
              return (
                <div style={{padding: 5, display:'flex', alignItems: 'flex-start'}}>
                  {capitalizeWord(key)}:
                  <textarea
                    type="text"
                    name={key}
                    style={{
                      padding: 5,
                      borderRadius: 5,
                      border: "1px solid grey",
                      marginLeft: 10,
                    }}
                    onChange={(e) => {
                      updateData({ [key]: e.target.value });
                    }}
                    value={data[key]}
                  />
                </div>
              );
            if (key !== "type" && key !== "radius" && key !== "severity")
              return (
                <div style={{padding: 5}}>
                  {capitalizeWord(key)}:
                  <input
                    type="text"
                    name={key}
                    style={{
                      padding: 5,
                      borderRadius: 5,
                      border: "1px solid grey",
                      marginLeft: 10,
                    }}
                    onChange={(e) => {
                      updateData({ [key]: e.target.value });
                    }}
                    value={data[key]}
                  />
                </div>
              );
          })}
          {/* </div>
          <div> */}
          {Object.keys(deviceFunctions[data.selectedFunction]).map((key) => {
            if (key === "type") return (
              <div style={{padding: 5, display: 'flex', alignItems:'center'}}>
                {capitalizeWord(key)}: <div style={{display:'flex'}}> <div style={{ marginRight: 5, marginLeft: 10 }}>
                <AlertIcons type={data.type} />
              </div><select
                  style={{
                    background: theme.base,
                    padding: 5,
                  }}
                  onChange={(e) => {
                    updateData({ [key]: e.target.value });
                  }}
                  value={data[key]}
                >
                  <option value="">-Select-</option>
                  {eventTypes.map((type) => {
                    return <option value={type}>{type}</option>;
                  })}
                </select></div>
              </div>
            )
            if (key === "radius") return (
              <div style={{padding: 5}}>
                {capitalizeWord(key)}:
                <input
                  type="number"
                  name={key}
                  style={{
                    padding: 5,
                    borderRadius: 5,
                    border: "1px solid grey",
                    marginLeft: 10,
                    width: 75,
                  }}
                  onChange={(e) => {
                    updateData({ [key]: e.target.value });
                  }}
                  value={data[key]}
                /> ft
              </div>
            )
            if (key === "severity") return (
              <div style={{padding: 5}}>
                
                <div style={{
                    width: "max-content", padding: 5, borderRadius: 5, background: alertColorScale(data[key])}} >{capitalizeWord(key)}: {getSeverityLevel(data[key])}</div>
                <Slider
                  style={{ width: "100%" }}
                  name="severity"
                  min={0}
                  max={100}
                  step={1}
                  value={100 - data[key]}
                  onChange={(e) => {
                    updateData({ [key]: 100 - e });
                  }}
                />
              </div>
            )
          })}
          </div>
        </div>
      )}
    </div>
  );
};

const eventTypes = [
  "911 Telephone Outage Emergency",
  "Administrative Message",
  "Air Quality Alert",
  "Air Stagnation Advisory",
  "Arroyo And Small Stream Flood Advisory",
  "Ashfall Advisory",
  "Ashfall Warning",
  "Avalanche Advisory",
  "Avalanche Warning",
  "Avalanche Watch",
  "Beach Hazards Statement",
  "Blizzard Warning",
  "Blizzard Watch",
  "Blowing Dust Advisory",
  "Blowing Dust Warning",
  "Brisk Wind Advisory",
  "Child Abduction Emergency",
  "Civil Danger Warning",
  "Civil Emergency Message",
  "Coastal Flood Advisory",
  "Coastal Flood Statement",
  "Coastal Flood Warning",
  "Coastal Flood Watch",
  "Dense Fog Advisory",
  "Dense Smoke Advisory",
  "Dust Advisory",
  "Dust Storm Warning",
  "Earthquake Warning",
  "Evacuation - Immediate",
  "Excessive Heat Warning",
  "Excessive Heat Watch",
  "Extreme Cold Warning",
  "Extreme Cold Watch",
  "Extreme Fire Danger",
  "Extreme Wind Warning",
  "Fire Warning",
  "Fire Weather Watch",
  "Flash Flood Statement",
  "Flash Flood Warning",
  "Flash Flood Watch",
  "Flood Advisory",
  "Flood Statement",
  "Flood Warning",
  "Flood Watch",
  "Freeze Warning",
  "Freeze Watch",
  "Freezing Fog Advisory",
  "Freezing Rain Advisory",
  "Freezing Spray Advisory",
  "Frost Advisory",
  "Gale Warning",
  "Gale Watch",
  "Hard Freeze Warning",
  "Hard Freeze Watch",
  "Hazardous Materials Warning",
  "Hazardous Seas Warning",
  "Hazardous Seas Watch",
  "Hazardous Weather Outlook",
  "Heat Advisory",
  "Heavy Freezing Spray Warning",
  "Heavy Freezing Spray Watch",
  "High Surf Advisory",
  "High Surf Warning",
  "High Wind Warning",
  "High Wind Watch",
  "Hurricane Force Wind Warning",
  "Hurricane Force Wind Watch",
  "Hurricane Local Statement",
  "Hurricane Warning",
  "Hurricane Watch",
  "Hydrologic Advisory",
  "Hydrologic Outlook",
  "Ice Storm Warning",
  "Lake Effect Snow Advisory",
  "Lake Effect Snow Warning",
  "Lake Effect Snow Watch",
  "Lake Wind Advisory",
  "Lakeshore Flood Advisory",
  "Lakeshore Flood Statement",
  "Lakeshore Flood Warning",
  "Lakeshore Flood Watch",
  "Law Enforcement Warning",
  "Local Area Emergency",
  "Low Water Advisory",
  "Marine Weather Statement",
  "Nuclear Power Plant Warning",
  "Radiological Hazard Warning",
  "Red Flag Warning",
  "Rip Current Statement",
  "Severe Thunderstorm Warning",
  "Severe Thunderstorm Watch",
  "Severe Weather Statement",
  "Shelter In Place Warning",
  "Short Term Forecast",
  "Small Craft Advisory",
  "Small Craft Advisory For Hazardous Seas",
  "Small Craft Advisory For Rough Bar",
  "Small Craft Advisory For Winds",
  "Small Stream Flood Advisory",
  "Snow Squall Warning",
  "Special Marine Warning",
  "Special Weather Statement",
  "Storm Surge Warning",
  "Storm Surge Watch",
  "Storm Warning",
  "Storm Watch",
  "Test",
  "Tornado Warning",
  "Tornado Watch",
  "Tropical Depression Local Statement",
  "Tropical Storm Local Statement",
  "Tropical Storm Warning",
  "Tropical Storm Watch",
  "Tsunami Advisory",
  "Tsunami Warning",
  "Tsunami Watch",
  "Typhoon Local Statement",
  "Typhoon Warning",
  "Typhoon Watch",
  "Urban And Small Stream Flood Advisory",
  "Volcano Warning",
  "Wind Advisory",
  "Wind Chill Advisory",
  "Wind Chill Warning",
  "Wind Chill Watch",
  "Winter Storm Warning",
  "Winter Storm Watch",
  "Winter Weather Advisory",
];

export default DeviceAction;
