import React, { useContext, useEffect, useState } from "react";
import { FaCloudSun, FaEye } from "react-icons/fa";
import { MdSunny } from "react-icons/md";
import { BsSunriseFill, BsSunset } from "react-icons/bs";
import {
  WiMoonrise,
  WiMoonset,
  WiMoonAltNew,
  WiSprinkle,
  WiBarometer,
} from "react-icons/wi";
import { IconContext } from "react-icons";
import ForecastSpread from "../ForecastSpread";
import {
  RiDropLine,
  RiTempColdFill,
  RiTempHotFill,
  RiWindyFill,
} from "react-icons/ri";
import { FaHouseChimney } from "react-icons/fa6";
import { PiWind } from "react-icons/pi";
import { useAPIData } from "hooks/useAPIData";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import { UniqueWidgetRefreshContext } from "App";
import Cookies from "js-cookie";
import useDataRefresh from "hooks/useDataRefresh";

const ForecastIcon = ({ icon, color = "white", size = 30 }) => (
  <IconContext.Provider value={{ color, size }}>{icon}</IconContext.Provider>
);

const ForecastDetails = ({ header, data, icons }) => (
  <ForecastSpread
    style={{ width: "100%", height: "100%" }}
    header={header}
    data={data}
    dataIcons={icons}
  />
);

const ForecastSection = ({ title, icon, data }) => (
  <div
    style={{
      flex: "1 1 25%",
      height: 150,
      width: "100%",
    }}
  >
    <div style={{ margin: 10 }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: title.includes("Weather Forecast")
            ? ""
            : "solid grey 2px",
        }}
      >
        <div>{title}</div>
        <ForecastIcon icon={icon} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: 12,
          marginTop: 5,
        }}
      >
        {data}
      </div>
    </div>
  </div>
);

export default function WeatherHome(
  {
    // currentData,
    // forecastHourlyData,
    // astronomyData,
  }
) {
  const isNarrowScreen = window.innerWidth < 1546;
  const [currentData, setCurrentData] = useState();
  const [forecastHourlyData, setForecastHourlyData] = useState();
  const [astronomyData, setAstronomyData] = useState();
  const [fetching, setFetching] = useState(true);
  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      // setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });
  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "WeatherHome";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    async function fetchData() {
      try {
        //get hourly forecast data
        FusionData.forceGet("getHourlyForecast").then((res) => {
          
          setForecastHourlyData(
            res.data.result.map((item) => {
              return {
                ...item,
                local_time: parseDateTime(item.iso8601.toString()),
              };
            })
          );
        });

        //get current conditons of current city
        FusionData.forceGet("currentCityConditions").then((res) => {
          setCurrentData(res.data.result[0]);
        });

        //get astronomy data
        FusionData.forceGet("getAstronomyData").then((res) => {
          setAstronomyData(res.data.result);
          
        });
        setFetching(false);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [uwf[UNIQUEKEY]]);

  const dataRefresh = useDataRefresh();
  useEffect(() => {
    const fetchData = async () => {
      try {
        // //get hourly forecast data
        FusionData.get("getHourlyForecast").then((res) => {
          
          setForecastHourlyData(
            res.data.result.map((item) => {
              return {
                ...item,
                local_time: parseDateTime(item.iso8601.toString()),
              };
            })
          );
        });

        //get current conditons of current city
        FusionData.get("currentCityConditions").then((res) => {
          setCurrentData(res.data.result[0]);
        });

        //get astronomy data
        FusionData.get("getAstronomyData").then((res) => {
          setAstronomyData(res.data.result);
          
        });
        setFetching(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [dataRefresh.widgetRefresh]);

  const parseDateTime = (datetime) => {
    
    const result = [];
    result.push(datetime[6], datetime[7], datetime[8], datetime[9]);
    result.push("-");
    result.push(datetime[2], datetime[3]);
    result.push("-");
    result.push(datetime[4], datetime[5]);
    result.push("T", datetime[0], datetime[1], ":00:00");

    
    

    return new Date(datetime);
  };
  

  const currentWeatherData = currentData
    ? {
        Temperature: `${currentData.temperature}° F`,
        "Wind SP": `${currentData.wind_speed} MPH`,
        Humidity: `${currentData.humidity}%`,
        "Comfort Level": `${currentData.comfort}° F`,
        Visibility: `${currentData.visibility} mi`,
        "Dew Point": `${currentData.dew_point}° F`,
        "Wind Direction": `${currentData.wind_short}`,
      }
    : {};

  const weatherIcons = [
    <RiTempHotFill />,
    <RiWindyFill />,
    <RiDropLine />,
    <FaHouseChimney />,
    <FaEye />,
    <WiSprinkle />,
    <PiWind />,
  ];

  const sunSectionData = astronomyData ? (
    <>
      <div
        style={{
          flex: 1 / 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <ForecastIcon icon={<BsSunriseFill />} size={50} />
        <div>{`Sunrise: ${astronomyData.sunrise}`}</div>
      </div>
      <div
        style={{
          flex: 1 / 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <ForecastIcon icon={<BsSunset />} size={50} />
        <div>{`Sunset: ${astronomyData.sunset}`}</div>
      </div>
    </>
  ) : null;

  const moonSectionData = astronomyData ? (
    <>
      <div
        style={{
          flex: 1 / 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <ForecastIcon icon={<WiMoonrise />} size={50} />
        <div>{`Moonrise: ${astronomyData.moonrise}`}</div>
      </div>
      <div
        style={{
          flex: 1 / 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <ForecastIcon icon={<WiMoonset />} size={50} />
        <div>{`Moonset: ${astronomyData.moonset}`}</div>
      </div>
    </>
  ) : null;

  if (fetching) {
    return <WidgetLoadingAnimation />;
  }

  if (currentData)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflow: "auto",
        }}
      >
        <WidgetTimeUpdate route={ROUTE} />
        {/* upper */}
        <div
          style={{
            display: "flex",
            flexDirection: isNarrowScreen ? "column" : "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {currentData && (
            <ForecastSection
              title={`${new Date(
                currentData.utc_time
              ).toDateString()} Weather Forecast`}
              icon={<FaCloudSun />}
              data={
                <ForecastDetails
                  header=""
                  data={currentWeatherData}
                  icons={weatherIcons}
                />
              }
            />
          )}
          <ForecastSection
            title="Sun"
            icon={<MdSunny />}
            data={sunSectionData}
          />
          <ForecastSection
            title="Moon"
            icon={<WiMoonAltNew />}
            data={moonSectionData}
          />
        </div>

        {/* lower */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 15,
          }}
        >
          <div>
            {currentData && (
              <div
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  width: "100%",
                  borderBottom: "solid grey 1px",
                }}
              >
                {`${new Date(
                  currentData.utc_time
                ).toDateString()} Hourly Forecast`}
              </div>
            )}
          </div>
          <div
            style={{
              marginTop: 15,
              display: "flex",
              flexDirection: isNarrowScreen ? "column" : "row",
              flexWrap: "wrap",
            }}
          >
            {forecastHourlyData &&
              forecastHourlyData.slice(0, 8).map((data, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    margin: "10px",
                  }}
                >
                  <ForecastDetails
                    header={new Date(data.iso8601).toLocaleTimeString()}
                    data={{
                      Temperature: `${data.temperature}° F`,
                      "Wind SP": `${data.wind_speed} MPH`,
                      Humidity: `${data.humidity}%`,
                      "Comfort Level": `${data.comfort}° F`,
                      Visibility: `${data.visibility} mi`,
                      "Dew Point": `${data.dew_point}° F`,
                      "Wind Direction": `${data.wind_short}`,
                    }}
                    icons={weatherIcons}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    );

    return <WidgetLoadingAnimation />
}
