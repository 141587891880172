import React, { useContext, useEffect, useState } from "react";
import ForecastSpread from "../ForecastSpread";
import VerticalForecastSpread from "./VerticalForecastSpread";
import ConditionIcons from "components/ConditionIcons";
import { FaEye, FaWind } from "react-icons/fa";
import { IconContext } from "react-icons";
import { DateToString } from "utils/stringUtils";
import { RiDropLine, RiTempHotFill, RiWindyFill } from "react-icons/ri";
import { FaHouseChimney } from "react-icons/fa6";
import { WiSprinkle } from "react-icons/wi";
import { PiWind } from "react-icons/pi";
import WidgetTimeUpdate from "components/Widgets/WidgetTimeUpdate";
import WidgetLoadingAnimation from "components/Widgets/WidgetLoadingAnimation";
import Cookies from "js-cookie";
import { ColorContext, UniqueWidgetRefreshContext } from "App";
import { useAPIData } from "hooks/useAPIData";
import useDataRefresh from "hooks/useDataRefresh";

const SegmentForecast = ({ data, days, selected, segment }) => {
  const theme = useContext(ColorContext);
  return data
    .filter(
      (item) => item.weekday === days[selected] && item.day_segment === segment
    )
    .map((item, index) => (
      <VerticalForecastSpread
        key={index}
        header={segment}
        items={[
          {
            icon: <ConditionIcons conditionId={item.icon} />,
            content: `${item.temperature}°F`,
          },
          {
            icon: (
              <IconContext.Provider value={{ size: 25 }}>
                <ConditionIcons conditionId={20} />
              </IconContext.Provider>
            ),
            content: `${item.precip_prob}%`,
          },
          {
            icon: <FaWind />,
            content: `${Math.round(item.wind_speed)}mph ${item.wind_short}`,
          },
        ]}
      />
    ));
};

const ForecastDetails = ({ data, days, selected, segment }) => {
  const theme = useContext(ColorContext);
  return data
    .filter(
      (item) => item.weekday === days[selected] && item.day_segment === segment
    )
    .map((item, index) => (
      <ForecastSpread
        key={index}
        header={segment}
        icon={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: 14,
              alignItems: "center",
            }}
          >
            <div>{`${item.temperature}°F`}</div>
            <IconContext.Provider value={{ size: 30 }}>
              <ConditionIcons conditionId={item.icon} />
            </IconContext.Provider>
          </div>
        }
        data={{
          Temperature: `${item.temperature}° F`,
          "Wind SP": `${item.wind_speed} MPH`,
          Humidity: `${item.humidity}%`,
          "Comfort Level": `${item.comfort}° F`,
          Visibility: `${item.visibility} mi`,
          "Dew Point": `${item.dew_point}° F`,
          "Wind Direction": `${item.wind_short}`,
        }}
        dataIcons={[
          <RiTempHotFill />,
          <RiWindyFill />,
          <RiDropLine />,
          <FaHouseChimney />,
          <FaEye />,
          <WiSprinkle />,
          <PiWind />,
        ]}
        style={{ width: "90%" }}
      />
    ));
};

export default function DetailedForecast({
  // data,
  //  days,
  selected,
}) {
  const theme = useContext(ColorContext);
  const [date, setDate] = useState();
  const [data, setData] = useState([]);
  const [days, setDays] = useState([]);

  useEffect(() => {
    const selectedItem = data.find((item) => item.weekday === days[selected]);
    if (selectedItem) {
      setDate(DateToString(new Date(selectedItem.iso8601 || new Date())));
    } else {
      setDate("No Data");
    }
  }, [selected, data]);

  const [fetching, setFetching] = useState(true);
  const FusionData = useAPIData({
    successCallback: () => {
      setFetching(false);
    },
    errorCallback: () => {
      // setError({ state: true, message: "Error fetching data" });
      setFetching(false);
    },
    fetchingCallback: () => {
      setFetching(true);
    },
  });
  const uwf = useContext(UniqueWidgetRefreshContext);
  const ROUTE = "getDetailedForecastData";
  const LOCATION = Cookies.get("city") + "-" + Cookies.get("state");
  const UNIQUEKEY = LOCATION + "-" + ROUTE;

  useEffect(() => {
    async function fetchData() {
      try {
        //get hourly forecast data
        //get detailed forecast data
        FusionData.forceGet(ROUTE).then((res) => {
          const now = new Date();
          

          const dayArray = [];
          //build array
          res.data.result.map((day) => {
            
            if (
              dayArray.filter(
                (x) => parseInt(x.day_of_week) === parseInt(day.day_of_week)
              ).length === 0
            ) {
              const order =
                parseInt(day.day_of_week) < now.getDay()
                  ? parseInt(day.day_of_week) + 7
                  : parseInt(day.day_of_week);

              dayArray.push({
                day_of_week: parseInt(day.day_of_week),
                weekday: day.weekday,
                order: order,
              });
            }
          });

          setDays(
            dayArray
              .sort((a, b) => {
                return a.order - b.order;
              })
              .map((x) => {
                return x.weekday;
              })
          );

          setData([...res.data.result]);
          setFetching(false);
        });
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [uwf[UNIQUEKEY]]);

  const dataRefresh = useDataRefresh();
  useEffect(() => {
    const fetchData = async () => {
      try {
        //get hourly forecast data
        //get detailed forecast data
        FusionData.forceGet(ROUTE).then((res) => {
          const now = new Date();
          

          const dayArray = [];
          //build array
          res.data.result.map((day) => {
            
            if (
              dayArray.filter(
                (x) => parseInt(x.day_of_week) === parseInt(day.day_of_week)
              ).length === 0
            ) {
              const order =
                parseInt(day.day_of_week) < now.getDay()
                  ? parseInt(day.day_of_week) + 7
                  : parseInt(day.day_of_week);

              dayArray.push({
                day_of_week: parseInt(day.day_of_week),
                weekday: day.weekday,
                order: order,
              });
            }
          });

          setDays(
            dayArray
              .sort((a, b) => {
                return a.order - b.order;
              })
              .map((x) => {
                return x.weekday;
              })
          );

          setData([...res.data.result]);
          setFetching(false);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetching(false);
      }
    };

    fetchData();
  }, [dataRefresh.widgetRefresh]);

  if (fetching) {
    return <WidgetLoadingAnimation />;
  }

  if (data.length > 0) return (
    <div
      style={{ display: "flex", flexDirection: "column", overflowY: "auto" }}
    >
      {/* Upper */}
      <WidgetTimeUpdate route={ROUTE} dataUpdateOnly />
      <div style={{ backgroundColor: theme.base }}>
        <div style={{ padding: 15 }}>
          {date && (
            <div
              style={{
                marginBottom: 15,
                borderBottom: "solid grey 1px",
                fontSize: 20,
              }}
            >
              {date !== "No Data"
                ? `${`${date.weekday_long} ${date.month} ${date.day}, ${date.year}`} Detailed Forecast`
                : "No data"}
            </div>
          )}
          {data && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <SegmentForecast
                data={data}
                days={days}
                selected={selected}
                segment="Morning"
              />
              <SegmentForecast
                data={data}
                days={days}
                selected={selected}
                segment="Afternoon"
              />
              <SegmentForecast
                data={data}
                days={days}
                selected={selected}
                segment="Evening"
              />
              <SegmentForecast
                data={data}
                days={days}
                selected={selected}
                segment="Night"
              />
            </div>
          )}
        </div>
      </div>

      {/* Lower */}
      <div style={{ backgroundColor: theme.base, marginTop: 15 }}>
        <div style={{ padding: 15 }}>
          {data && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <ForecastDetails
                  data={data}
                  days={days}
                  selected={selected}
                  segment="Morning"
                />
                <ForecastDetails
                  data={data}
                  days={days}
                  selected={selected}
                  segment="Evening"
                />
              </div>

              <div
                style={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: 250,
                }}
              >
                <ForecastDetails
                  data={data}
                  days={days}
                  selected={selected}
                  segment="Afternoon"
                />
                <ForecastDetails
                  data={data}
                  days={days}
                  selected={selected}
                  segment="Night"
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
  return <WidgetLoadingAnimation />
}
