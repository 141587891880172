import * as d3 from "d3";
import { Lerp } from "./mathUtils";

const qualityNumbers = {
  G: "#14FF00",
  M: "#ADFF00",
  S: "#FFE500",
  U: "#D89B40",
  V: "#FF701F",
  H: "#BF0000",
};

export function GetQualityColor(quality) {
  if (quality === "*") return "";

  return qualityNumbers[quality];

  //old code that I thought was cool ;-;
  
  // let result = Lerp(0x00ff00, 0xff0000, qualityNumbers[quality] / 5)
  //   .toString(16)
  //   .split(".")[0];

  // if (result.length < 6) {
  //   result = ["0", "0", ...result].join("");
  // }

  // result = "#" + result;
  
  // return result;
}

export const temperatureColorScale = d3
  .scaleLinear()

  .domain([-20,       -5,        8,         16,        26,        48,        0,         88,        106])
  .range(["#401727", "#ED0171", "#A4059E", "#5E0AA9", "#2B0C9A", "#1083C6", "#38BF37", "#DCDE00", "#FF5B00"]);

export const rainColorScale = d3
  .scaleLinear()
  .domain([0, 100])
  .range(["#FF00FF", "#0000FF", "#00FFFF", "#00FF00", "#FFFF00", "#FF0000"]);

 
