import React, { useContext, useEffect, useState } from "react";
import { BsChevronLeft } from "react-icons/bs";
import { FaCog, FaPause, FaPencilAlt, FaPlay } from "react-icons/fa";
import {
  MdOutlineSignalCellularAlt,
  MdOutlineSignalCellularAlt1Bar,
  MdOutlineSignalCellularAlt2Bar,
} from "react-icons/md";
import * as api from "apis/FusionAPI";
import PageIndex from "components/UI/PageIndex";
import { FlipIndex } from "utils/mathUtils";
import ExportModal from "./ExportModal";
import Popup from "reactjs-popup";
import { useLocation } from "react-router-dom";
import { ColorContext } from "App";
import { flip } from "@turf/turf";
import { extractDateTimeFromPict } from "utils/dateUtils";

let playingVideo = false;

const CurrentDevices = ({
  data,
  setShowDeviceSettings,
  selectedDevice,
  setSelectedDevice,
  showCurrentDevice,
  setShowCurrentDevice,
  setSelectedDeviceData,
  nodeList,
  pageIndex,
  setPageIndex,
  handleSubmit,
  getNodeData,
  timeMultiplier,
  setTimeMultiplier,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  successMessage,
  setSuccessMessage,
  showExportModal,
  setShowExportModal,
  loadingMessage,
  setTimeSpan,
}) => {
  const location = useLocation();

  const theme = useContext(ColorContext);

  const [isEditing, setIsEditing] = useState(false);
  const [editAlias, setEditAlias] = useState("");

  const [showAdvancedDate, setShowAdvancedDate] = useState(false);
  const [hasSearchedBefore, setHasSearchedBefore] = useState(false);

  const [currImageIndex, setCurrImageIndex] = useState(0);
  const [playPauseIcon, setPlayPauseIcon] = useState(false);
  let currImageIndexLocal = 0;

  const [initialPlayState, setInitialPlayState] = useState(true);
  const [flippedDataForPageDisplay, setFlippedDataForPageDisplay] = useState(
    data ? [...data] : []
  );

  const [uniqueImages, setUniqueImages] = useState({});

  useEffect(() => {
    
  }, [uniqueImages]);

  const calibrationType = [
    "Lower Pressure Sensor",
    "Lower Pressure Sensor + SDI-12 Soil Moisture",
    "Lower Pressure Sensor + Waterlog Rain Sensor",
    "Lower Pressure Sensor + Intellisense Rain Sensor",
    "Water Level Radar",
  ];

  useEffect(() => {
    setPlayPauseIcon(false);
    playingVideo = false;
    const interval = setInterval(runImage, 500);

    return () => clearInterval(interval);
  }, [showCurrentDevice, selectedDevice, pageIndex]);

  useEffect(() => {
    currImageIndexLocal = 0;
    playingVideo = false;
    setCurrImageIndex(0);
  }, [showCurrentDevice, selectedDevice]);

  useEffect(() => {
    if (flippedDataForPageDisplay[selectedDevice]?.data.length < 1) return;

    const index = uniqueImages[
      selectedDeviceIndexToNodeId(selectedDevice)
    ]?.picts.indexOf(
      flippedDataForPageDisplay[selectedDevice].data[pageIndex - 1].img_link_url
    );

    const flippedNewIndex = index;

    currImageIndexLocal = flippedNewIndex;
    playingVideo = false;
    setCurrImageIndex(flippedNewIndex);
  }, [pageIndex]);

  useEffect(() => {
    if (!data || !data[selectedDevice] || !data[selectedDevice]?.data) {
      setShowCurrentDevice(false);
      console.log("Broken 1");
    }
  }, [showCurrentDevice]);

  useEffect(() => {
    let d = [...data];
    const temp = d.map((device) => {
      return {
        ...device,
        data: device.data.sort((a, b) => new Date(a.ts) - new Date(b.ts)),
      };
    });
    setFlippedDataForPageDisplay(temp);

    const uniqueImagesList = {};
    for (let i = 0; i < temp.length; i++) {
      if (!uniqueImagesList[temp[i].nodeId] && temp[i].data.length > 0)
        uniqueImagesList[temp[i].nodeId] = {
          IMEI: temp[i].data[0].IMEI,
          picts: [],
        };
      for (let j = 0; j < temp[i].data.length; j++) {
        if (
          !uniqueImagesList[temp[i].nodeId].picts.includes(
            temp[i].data[j].img_link_url
          )
        )
          uniqueImagesList[temp[i].nodeId].picts.push(
            temp[i].data[j].img_link_url
          );
      }
    }
    
    setUniqueImages(uniqueImagesList);
  }, [data]);

  useEffect(() => {
    if (hasSearchedBefore) setSuccessMessage("Search Successful");

    setTimeout(() => {
      setSuccessMessage("");
      setHasSearchedBefore(true);
    }, 5000);
  }, [data]);

  const signalStrength = (rssi) => {
    rssi = -parseInt(rssi);
    if (rssi <= -50)
      return (
        <div>
          <MdOutlineSignalCellularAlt /> (High)
        </div>
      );
    else if (rssi <= -71)
      return (
        <div>
          <MdOutlineSignalCellularAlt2Bar /> (Medium)
        </div>
      );
    else if (rssi <= -100)
      return (
        <div>
          <MdOutlineSignalCellularAlt1Bar /> (Low)
        </div>
      );
    else return "No Signal";
  };

  useEffect(() => {
    setCurrImageIndex(currImageIndex);
  }, [currImageIndex]);

  const playPauseButton = () => {
    playingVideo = !playingVideo;
    setPlayPauseIcon(playingVideo);
  };

  const selectedDeviceIndexToNodeId = (selectedDeviceIndex) => {
    const id = flippedDataForPageDisplay[selectedDeviceIndex].data[0].node_id;
    return id;
  };

  const runImage = () => {
    if (playingVideo) {
      const max_frames =
        uniqueImages[selectedDeviceIndexToNodeId(selectedDevice)]?.picts
          .length || 0;

      currImageIndexLocal += 1;
      currImageIndexLocal %= max_frames;
      let newIndex = currImageIndexLocal;

      setCurrImageIndex(newIndex);
    }
  };

  const handleSaveAlias = async () => {
    const response = await api.editAwareAlias(
      flippedDataForPageDisplay[selectedDevice].data[pageIndex - 1].node_id,

      editAlias
    );

    setIsEditing(false);
    handleSubmit();
    console.log("Submit broken 1");
    getNodeData();
  };

  const formatDateTimeLocal = (date) => {
    const d = new Date(date);
    const pad = (num) => String(num).padStart(2, "0");

    const formattedDate = `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(
      d.getDate()
    )}T${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}`;

    return formattedDate;
  };

  const getStatus = (timestamp) => {
    const currentTime = Date.now();
    const timeDifference = (currentTime - timestamp) / (1000 * 60); // convert to minutes

    if (timeDifference > 70) {
      return "Offline";
    } else if (timeDifference > 30) {
      return "Slow";
    } else {
      return "Online";
    }
  };

  const getStatusColor = (timestamp) => {
    const currentTime = Date.now();
    const timeDifference = (currentTime - timestamp) / (1000 * 60); // convert to minutes

    if (timeDifference > 70) {
      return "#C73B1D";
    } else if (timeDifference > 30) {
      return "#D9AF27";
    } else {
      return theme.primaryHighlight;
    }
  };

  console.log(
    "Data in current devices:\n",
    selectedDevice,
    selectedDeviceIndexToNodeId(selectedDevice),
    uniqueImages,
    uniqueImages[selectedDeviceIndexToNodeId(selectedDevice)],
    currImageIndex,
    extractDateTimeFromPict(
      uniqueImages[selectedDeviceIndexToNodeId(selectedDevice)]?.picts[
        currImageIndex
      ]
    )
  );

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          flexDirection: "column",
          gap: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: theme.primaryShadow,
            borderRadius: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "center",
            }}
          >
            <select
              style={{
                background: theme.primaryHighlight,
                padding: 10,
                borderRadius: 10,
                margin: 10,
                width: "100%",
              }}
              value={timeMultiplier}
              onChange={(e) => {
                if (e.target.value === "-1") {
                  return;
                }
                if (e.target.value === "0") {
                  setTimeMultiplier(parseInt(e.target.value));
                  setShowAdvancedDate(true);
                } else {
                  setTimeMultiplier(parseInt(e.target.value));
                  setShowAdvancedDate(false);
                  setStartTime(Date.now() - parseInt(e.target.value) * 60000);
                  setEndTime(Date.now());
                  setTimeSpan(parseInt(e.target.value));
                }
              }}
            >
              <option value="-1">Time Period</option>
              <option value="0">Custom</option>
              <option value="1">1 Minute</option>
              <option value="2">2 Minutes</option>
              <option value="5">5 Minutes</option>
              <option value="10">10 Minutes</option>
              <option value="15">15 Minutes</option>
              <option value="30">30 Minutes</option>
              <option value="60">1 Hour</option>
              <option value="120">2 Hours</option>
              <option value="180">3 Hours</option>
              <option value="240">4 Hours</option>
              <option value="300">5 Hours</option>
              <option value="600">10 Hours</option>
              <option value="720">12 Hours</option>
              <option value="1440">1 Day</option>
              <option value="10080">7 Days</option>
              <option value="43200">30 Days</option>
            </select>
            <select
              style={{
                background: theme.primaryHighlight,
                padding: 10,
                borderRadius: 10,
                margin: 10,
                width: "100%",
              }}
            >
              <option value="0">Data Aggregation</option>
            </select>
          </div>
          {showExportModal && (
            <Popup
              open={showExportModal}
              closeOnDocumentClick={false}
              onClose={() => setShowExportModal(false)}
            >
              <ExportModal
                startTime={startTime}
                setStartTime={setStartTime}
                endTime={endTime}
                setEndTime={setEndTime}
                formatDateTimeLocal={formatDateTimeLocal}
                data={data}
                selectedDevice={selectedDevice}
                setShowExportModal={setShowExportModal}
              />
            </Popup>
          )}
          {showAdvancedDate && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: 10,
              }}
            >
              Time Period
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                Date From
                <input
                  style={{
                    background: theme.primaryHighlight,
                    padding: 10,
                    borderRadius: 10,
                    marginBottom: 10,
                  }}
                  type="datetime-local"
                  value={formatDateTimeLocal(startTime)}
                  onChange={(e) =>
                    setStartTime(new Date(e.target.value).getTime())
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                Date To
                <input
                  style={{
                    background: theme.primaryHighlight,
                    padding: 10,
                    borderRadius: 10,
                  }}
                  type="datetime-local"
                  value={formatDateTimeLocal(endTime)}
                  onChange={(e) =>
                    setEndTime(new Date(e.target.value).getTime())
                  }
                />
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              style={{
                background: theme.primaryHighlight,
                padding: 10,
                borderRadius: 10,
                margin: 10,
                width: "max-content",
              }}
              onClick={() => {
                setShowExportModal(true);
              }}
            >
              Export
            </button>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 10,
              }}
            >
              <div
                style={{
                  color: "green",
                  marginRight: 20,
                }}
              >
                {successMessage}
              </div>
              <div
                style={{
                  color: "white",
                  marginRight: 20,
                }}
              >
                {loadingMessage}
              </div>
              <button
                onClick={() => {
                  setTimeMultiplier(1440);
                  handleSubmit();
                  console.log("Submit broken 2");
                }}
              >
                Cancel
              </button>
              <button
                style={{
                  background: loadingMessage ? "gray" : theme.primaryHighlight,
                  padding: 10,
                  borderRadius: 10,
                  margin: 10,
                  width: "max-content",
                }}
                onClick={() => {
                  if (!loadingMessage) {
                    handleSubmit();
                    console.log("Submit broken 3");
                  }
                }}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: theme.primaryShadow,
            borderRadius: 10,
          }}
        >
          {showCurrentDevice && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignContent: "center",
                flexDirection: "column",
                alignItems: "center",
                padding: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignContent: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    cursor: "pointer",
                    alignItems: "center",
                    display: "flex",
                    width: 100,
                  }}
                  onClick={() => {
                    console.log("Broken 2");
                    setShowCurrentDevice(false);
                  }}
                >
                  <BsChevronLeft style={{ top: 20 }} size={20} />
                  <div style={{ marginLeft: 10 }}>Back</div>
                </div>
                <div style={{ fontSize: 20, fontWeight: "bold" }}>
                  {showCurrentDevice ? "Device Data:" : "All Devices"}{" "}
                </div>
                {showCurrentDevice && (
                  <button
                    style={{
                      width: 100,
                    }}
                    onClick={() => setShowDeviceSettings(true)}
                  >
                    <FaCog />
                  </button>
                )}
              </div>
              {flippedDataForPageDisplay[selectedDevice] &&
                flippedDataForPageDisplay[selectedDevice].data[pageIndex - 1]
                  ?.ts &&
                flippedDataForPageDisplay[selectedDevice]?.data.length !==
                  0 && (
                  <DataPair
                    label="Time Stamp"
                    value={new Date(
                      flippedDataForPageDisplay[selectedDevice].data[
                        pageIndex - 1
                      ].ts
                    ).toLocaleString()}
                  />
                )}
            </div>
          )}
          {showCurrentDevice &&
            flippedDataForPageDisplay[selectedDevice]?.data.length === 0 &&
            "No data available for this device and selected time period."}
          <div style={{ display: "flex" }}></div>
          {!showCurrentDevice && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "6fr 2fr 2fr 1fr",
                color: theme.fontColor,
                fontWeight: "bold",
                padding: 10,
                marginBottom: 7,
              }}
            >
              <div>Name</div>
              <div>ID</div>
              <div>Status</div>
            </div>
          )}
          {!showCurrentDevice && flippedDataForPageDisplay.length > 0 && (
            <div>
              {nodeList.map((device, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "grid",
                      gridTemplateColumns: "6fr 2fr 2fr 1fr",
                      borderTop: "1px solid grey",
                      padding: 10,
                      cursor: "pointer",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setSelectedDevice(index);
                      setPageIndex(
                        flippedDataForPageDisplay[index].data.length
                      );
                      setShowCurrentDevice(true);
                    }}
                  >
                    <p
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {device.rawData.node_alias}
                    </p>
                    <p>{device.rawData.node_id}</p>

                    <div
                      style={{
                        backgroundColor: getStatusColor(device.rawData.ts),
                        border:
                          device.status === "Active" ? "0px" : "1px solid #ccc",
                        borderRadius: 8,
                        padding: 5,
                        width: 80,
                        textAlign: "center",
                        boxSizing: "border-box",
                        height: 30,
                      }}
                    >
                      {getStatus(device.rawData.ts)}
                    </div>
                    <button onClick={() => setShowDeviceSettings(true)}>
                      <FaCog />
                    </button>
                  </div>
                );
              })}
            </div>
          )}
          {!showCurrentDevice &&
            flippedDataForPageDisplay.length === 0 &&
            "No data available for this time period."}
          {showCurrentDevice &&
            flippedDataForPageDisplay[selectedDevice].data[pageIndex - 1] &&
            flippedDataForPageDisplay[selectedDevice] &&
            flippedDataForPageDisplay[selectedDevice]?.data.length > 0 && (
              <div>
                <div
                  style={{
                    overflow: "auto",
                    height:
                      location.pathname === "/system/integrations"
                        ? window.innerHeight - 475
                        : window.innerHeight - 440,
                  }}
                >
                  <div>
                    <DataPair
                      label="Entity Name"
                      value={
                        flippedDataForPageDisplay[selectedDevice].data[0]
                          .node_name
                      }
                    />
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <DataPair
                        label="Device Alias"
                        value={
                          isEditing ? (
                            <>
                              <input
                                type="text"
                                value={editAlias}
                                onChange={async (e) => {
                                  setEditAlias(e.target.value);
                                }}
                                style={{
                                  borderRadius: 5,
                                  padding: 5,
                                  border: "1px solid white",
                                  backgroundColor: theme.base,
                                  color: theme.fontColor,
                                }}
                              />
                              <button
                                onClick={handleSaveAlias}
                                style={{ marginLeft: 10 }}
                              >
                                Save
                              </button>
                            </>
                          ) : (
                            flippedDataForPageDisplay[selectedDevice].data[0]
                              .node_alias
                          )
                        }
                      />
                      {!isEditing && (
                        <button
                          onClick={() => {
                            setIsEditing(true);
                            setEditAlias(
                              flippedDataForPageDisplay[selectedDevice].data[
                                pageIndex - 1
                              ].node_alias
                            );
                          }}
                          style={{ marginLeft: 10 }}
                        >
                          <FaPencilAlt />
                        </button>
                      )}
                    </div>
                    <DataPair
                      label="Signal Strength (Bars)"
                      value={signalStrength(
                        flippedDataForPageDisplay[selectedDevice].data[
                          pageIndex - 1
                        ].rssi
                      )}
                    />
                    <DataPair
                      label="Battery"
                      value={
                        flippedDataForPageDisplay[selectedDevice].data[
                          pageIndex - 1
                        ].battery
                      }
                      units="v"
                    />
                    <RateIndicator
                      label="Rise Rate Indicator"
                      value={
                        flippedDataForPageDisplay[selectedDevice].data[
                          pageIndex - 1
                        ].ffi1
                      }
                    />
                    <RateIndicator
                      label="Drop Rate Indicator"
                      value={
                        flippedDataForPageDisplay[selectedDevice].data[
                          pageIndex - 1
                        ].drInd
                      }
                    />
                    <RateIndicator
                      label="Depth Trigger Indicator"
                      value={
                        flippedDataForPageDisplay[selectedDevice].data[
                          pageIndex - 1
                        ].depthInd
                      }
                    />
                    <DataPair
                      label="Cell Signal Strength"
                      value={
                        "-" +
                        flippedDataForPageDisplay[selectedDevice].data[
                          pageIndex - 1
                        ].rssi
                      }
                      units={"dB"}
                    />
                    <DataPair
                      label="IMEI #"
                      value={
                        flippedDataForPageDisplay[selectedDevice].data[
                          pageIndex - 1
                        ].IMEI
                      }
                    />
                    <DataPair
                      label="Node ID"
                      value={
                        flippedDataForPageDisplay[selectedDevice].data[
                          pageIndex - 1
                        ].node_id
                      }
                    />
                    <DataPair
                      label="GPS Location"
                      value={`${
                        flippedDataForPageDisplay[selectedDevice].data[
                          pageIndex - 1
                        ].lat
                      }, ${
                        flippedDataForPageDisplay[selectedDevice].data[
                          pageIndex - 1
                        ].lon
                      }`}
                    />
                    <DataPair
                      label="Elevation"
                      value={
                        flippedDataForPageDisplay[selectedDevice].data[
                          pageIndex - 1
                        ].elev +
                        " ft/" +
                        (
                          flippedDataForPageDisplay[selectedDevice].data[
                            pageIndex - 1
                          ].elev * 0.3048
                        ).toFixed(3) +
                        " m"
                      }
                    />
                    <DataPair
                      label="Barometric Pressure"
                      value={
                        flippedDataForPageDisplay[selectedDevice].data[
                          pageIndex - 1
                        ].baro +
                        " mBar/" +
                        (
                          flippedDataForPageDisplay[selectedDevice].data[
                            pageIndex - 1
                          ].baro * 0.02952998
                        ).toFixed(3) +
                        " inHg"
                      }
                    />
                    <DataPair
                      label="Water Level Adjustment"
                      value={
                        flippedDataForPageDisplay[selectedDevice].data[
                          pageIndex - 1
                        ].water_level_adj +
                        " in/" +
                        (
                          flippedDataForPageDisplay[selectedDevice].data[
                            pageIndex - 1
                          ].water_level_adj / 12
                        ).toFixed(3) +
                        " ft"
                      }
                    />
                    <DataPair
                      label="Depth Measurement"
                      value={
                        flippedDataForPageDisplay[selectedDevice].data[
                          pageIndex - 1
                        ].depth1 +
                        " in/" +
                        (
                          flippedDataForPageDisplay[selectedDevice].data[
                            pageIndex - 1
                          ].depth1 / 12
                        ).toFixed(3) +
                        " ft"
                      }
                    />
                    <DataPair
                      label="Sensor Depth"
                      value={
                        flippedDataForPageDisplay[selectedDevice].data[
                          pageIndex - 1
                        ].water_level_adj +
                        flippedDataForPageDisplay[selectedDevice].data[
                          pageIndex - 1
                        ].depth1 +
                        " in/" +
                        (
                          (flippedDataForPageDisplay[selectedDevice].data[
                            pageIndex - 1
                          ].water_level_adj +
                            flippedDataForPageDisplay[selectedDevice].data[
                              pageIndex - 1
                            ].depth1) /
                          12
                        ).toFixed(3) +
                        " ft"
                      }
                    />
                    <DataPair
                      label="Water Temperature"
                      value={
                        flippedDataForPageDisplay[selectedDevice].data[
                          pageIndex - 1
                        ].h2oTemp +
                        "°C/" +
                        (
                          (flippedDataForPageDisplay[selectedDevice].data[
                            pageIndex - 1
                          ].h2oTemp *
                            9) /
                            5 +
                          32
                        ).toFixed(2) +
                        " °F"
                      }
                    />
                    <DataPair
                      label="Device Type"
                      value={
                        calibrationType[
                          flippedDataForPageDisplay[selectedDevice].data[
                            pageIndex - 1
                          ].calType
                        ]
                      }
                    />
                    {flippedDataForPageDisplay[selectedDevice].data[
                      pageIndex - 1
                    ].soilTempSDI &&
                      flippedDataForPageDisplay[selectedDevice].data[
                        pageIndex - 1
                      ].soilSDI && (
                        <DataPair
                          label="External Device Data"
                          value={
                            flippedDataForPageDisplay[selectedDevice].data[
                              pageIndex - 1
                            ].soilSDI +
                            ", " +
                            flippedDataForPageDisplay[selectedDevice].data[
                              pageIndex - 1
                            ].soilTempSDI
                          }
                        />
                      )}
                    <DataPair
                      label="Gateway Type"
                      value={
                        flippedDataForPageDisplay[selectedDevice].data[
                          pageIndex - 1
                        ].gatewayType
                          ? "Cellular"
                          : "Satellite"
                      }
                    />
                    <div style={{ padding: 10, display: "flex" }}>
                      <div
                        style={{
                          padding: 10,
                          display: "flex",
                          alignItems: "flex-start",
                          position: "relative", // Add this line
                          width: 320,
                          height: 240,
                        }}
                      >
                        {uniqueImages[
                          selectedDeviceIndexToNodeId(selectedDevice)
                        ]?.picts && (
                          <img
                            src={
                              uniqueImages[
                                selectedDeviceIndexToNodeId(selectedDevice)
                              ]?.picts[
                                currImageIndex %
                                  uniqueImages[
                                    selectedDeviceIndexToNodeId(selectedDevice)
                                  ]?.picts.length || 0
                              ] || "failure"
                            }
                            alt="Device Video"
                            style={{ width: 320, height: 240 }} // Ensure the image fits the container
                          />
                        )}
                        {data[selectedDevice].data.length > 1 && (
                          <div
                            style={{
                              position: "absolute",
                              top: initialPlayState ? "50%" : "15%",
                              right: initialPlayState ? "" : "0%",
                              left: initialPlayState ? "50%" : "",
                              transform: "translate(-50%, -50%)",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                              width: initialPlayState ? 300 : "",
                              height: initialPlayState ? 220 : "",
                              backgroundColor: initialPlayState
                                ? "rgba(0, 0, 0, 0.3)"
                                : "", // Optional: Add a semi-transparent background for better visibility
                              padding: 10, // Optional: Add padding for better appearance
                              borderRadius: 5, // Optional: Add border-radius for rounded corners
                            }}
                          >
                            <button
                              onClick={() => {
                                playPauseButton();
                                setInitialPlayState(false);
                              }}
                            >
                              {playPauseIcon ? <FaPause /> : <FaPlay />}
                            </button>
                          </div>
                        )}
                        <div
                          style={{
                            position: "absolute",
                            bottom: 15,
                            left: 20,
                            width: 320,
                            height: 20,
                            textShadow: "2px 2px 2px black",
                          }}
                        >
                          {Object.keys(uniqueImages).length > 0 &&
                            extractDateTimeFromPict(
                              uniqueImages[
                                selectedDeviceIndexToNodeId(selectedDevice)
                              ]?.picts[currImageIndex]
                            ).toLocaleString()}{" "}
                          ({currImageIndex + 1}/
                          {uniqueImages[
                            selectedDeviceIndexToNodeId(selectedDevice)
                          ]?.picts.length || 0}
                          )
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <PageIndex
                  pageIndex={FlipIndex(
                    pageIndex,
                    data[selectedDevice].data.length
                  )}
                  setPageIndex={(index) => {
                    const num = FlipIndex(
                      index,
                      data[selectedDevice].data.length
                    );

                    setPageIndex(num);
                  }}
                  maxPages={data[selectedDevice].data.length}
                />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

const DataPair = ({ label, value, units = null }) => {
  if (value !== null && value !== undefined)
    return (
      <div style={{ padding: 10, display: "flex" }}>
        <div style={{ fontWeight: "bold", marginRight: 10 }}>{label}:</div>
        {value} {units}
      </div>
    );

  return null;
};

const RateIndicator = ({ label, value, units = null }) => {
  let displayValue = value;
  let style = {};

  if (label === "Rise Rate Indicator") {
    if (value === 0) {
      displayValue = "No Flooding Alert";
    } else if (value === 1) {
      displayValue = "Flooding Alert!";
      style = { color: "red" };
    }
  }

  if (label === "Drop Rate Indicator") {
    if (value === 0) {
      displayValue = "No Drop Rate Alert";
    } else if (value === 1) {
      displayValue = "Drop Rate Alert!";
      style = { color: "red" };
    }
  }

  if (label === "Depth Trigger Indicator") {
    if (value === 0) {
      displayValue = "No Depth Trigger Alert";
    } else if (value === 1) {
      displayValue = "Depth Trigger Alert!";
      style = { color: "red" };
    }
  }

  if (value !== null && value !== undefined) {
    return (
      <div style={{ padding: 10, display: "flex" }}>
        <div style={{ fontWeight: "bold", marginRight: 10 }}>{label}:</div>
        <div style={style}>
          {displayValue} {units}
        </div>
      </div>
    );
  }

  return null;
};

export default CurrentDevices;
