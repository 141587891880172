import React, { useState, useEffect, useContext } from "react";
import * as api from "apis/FusionAPI";
import MixedChart from "components/Charts/MixedChart";
import { borderColor, borderRadius, height, width } from "@mui/system";
import { scales } from "chart.js";
import Popup from "reactjs-popup";
import { FaChevronDown } from "react-icons/fa";
import { DateToString } from "utils/stringUtils";
import DropdownSelect from "components/UI/DropdownSelect";
import { FlipIndex } from "utils/mathUtils";
import { ColorContext, LayoutSizeContext, SidebarContext } from "App";
import { extractDateTimeFromPict } from "utils/dateUtils";
import { useNavigate } from "react-router-dom";

const example = {
  dataSets: {
    labels: ["example1", "example2", "example3"], //lables on the bottom
    datasets: [
      {
        type: "line", //'bar' or 'line'
        label: "Example", //lable of the line itself (written in they key)
        data: [3, 2, 1], //data points
        backgroundColor: "blue", //line and/or bar color
      },
      {
        type: "bar", //'bar' or 'line'
        label: "Example", //lable of the line itself (written in they key)
        data: [1, 2, 3], //data points
        backgroundColor: "#98ff18", //line and/or bar color
      },
    ],
  },
  options: {
    interaction: {
      mode: "index",
      intersect: false,
    },
    title: {
      display: true,
      text: "Example Chart", //title of the chart
    },
  },
};

export default function AwareDeviceGraphs({
  arrayOfDevices,
  widget,
  nodeData,
  nodeList,
  simpleNodeData,
  selectedDevice,
  setSelectedDevice,
  setPageIndex,
  setShowCurrentDevice,
  updatedSelectedDevice,
  startTime,
  endTime,
  timeSpan,
  noTitle = false,
}) {
  const navigate = useNavigate();

  const theme = useContext(ColorContext);
  const layout = useContext(LayoutSizeContext);
  const sidebarOpen = useContext(SidebarContext);

  const [selectedNodeId, setSelectedNodeId] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedDataType, setSelectedDataType] = useState("sensordepths");
  const [chartData, setChartData] = useState(null);
  const [redLineData, setRedLineData] = useState([]);

  const [lastDataPoints, setLastDataPoints] = useState([]);

  const [unitType, setUnitType] = useState(false);

  const getRandomColor = () => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r},${g},${b})`;
  };
  const [defaultIndexSet, setDefaultIndexSet] = useState(false);
  const [colors, setColors] = useState({});
  const [timeStamps, setTimeStamps] = useState([]);

  useEffect(() => {
    let temp = { ...selectedNodes };
    nodeList.forEach((node, index) => {
      if (index == selectedDevice)
        temp = { ...temp, [node.rawData.node_alias]: true };
      else temp = { ...temp, [node.rawData.node_alias]: false };
    });
    setSelectedNodes({ ...temp });
  }, [selectedDevice, updatedSelectedDevice]);

  useEffect(() => {
    if (nodeList) {
      nodeList.forEach((node) => {
        console.log("node", node);
        colors[node.nodeId] = getRandomColor();
      });
    }
  }, [nodeList]);

  useEffect(() => {
    if (arrayOfDevices && arrayOfDevices.length > 0) {
      setSelectedNodeId([arrayOfDevices[0].nodeId]);
    }
  }, [arrayOfDevices]);

  useEffect(() => {
    if (arrayOfDevices && nodeData && nodeList) {
      if (defaultIndexSet === false) {
        //find node alias from index 0

        let na = nodeList[0]?.node_alias;
        // let na = nodeData[0].data[0].node_alias;
        if (na) {
          setSelectedNodes({
            [na]: true,
          });
        } else {
          console.log("NA NOT FOUND", nodeList);
        }
        setDefaultIndexSet(true);
      }
      const checkedTimes = [];
      const redLines = [];

      //get the earliest time of of all selected nodes
      let times = [startTime];

      for (
        let interval = startTime + 60000;
        interval <= endTime;
        interval += 60000
      ) {
        times.push(interval);
      }

      if (!times[times.length - 1] === endTime) times.push(endTime);

      times = times.map((t, i) => {
        let val = new Date(t);
        val = val.setSeconds(0, 0);
        return new Date(val);
      });

      const allTimestamps = times.map((v) => new Date(v).toLocaleString());
      setTimeStamps([...new Set(allTimestamps)]);

      setLastDataPoints(
        Object.keys(selectedNodes)
          .filter((item) => {
            return selectedNodes[item] === true;
          })
          .map((nodeAlias) => {
            let lastPoint = nodeData.find((deviceData) => {
              if (deviceData.data.length > 0)
                return (
                  deviceData.data[0].node_alias === nodeAlias &&
                  selectedNodes[nodeAlias] === true
                );
              else return false;
            });

            lastPoint = lastPoint.data[lastPoint.data.length - 1];

            console.log("LastPoint:", lastPoint);

            let node = -1;

            nodeList.forEach((item, index) => {
              if (node < 0 && item.rawData.node_alias === nodeAlias)
                node = index;
            });

            return {
              nodeId: lastPoint.NI,
              node: node,
              nodeAlias: nodeAlias,
              latestDate: new Date(lastPoint.ts).toLocaleString(),
              sensorDepths: lastPoint.depth1 + lastPoint.water_level_adj,
              depths: lastPoint.depth1,
              temperature: lastPoint.h2oTemp,
              baroPressure: lastPoint.baro,
              battery: lastPoint.battery,
              pict: lastPoint.img_link_url,
              alert:
                lastPoint.ffi1 || lastPoint.drInd || lastPoint.depthInd
                  ? true
                  : false,
            };
          })
      );

      const chartData = {
        labels: [...new Set(allTimestamps)],
        datasets: Object.keys(selectedNodes)
          .filter((item) => {
            return (
              selectedNodes[item] === true &&
              nodeData.find((deviceData) => {
                const validPoints = deviceData.data
                  .filter((p) => {
                    let date = new Date(p.ts).setSeconds(0, 0);
                    date = new Date(date).toLocaleString();
                    return allTimestamps.includes(date);
                  })
                  .map((v) => v);

                if (validPoints.length > 0)
                  return deviceData.data[0].node_alias === item;
                else return false;
              })
            );
          })
          .map((nodeAlias, index) => {
            let nodeDataArray = nodeData.find((deviceData) => {
              if (deviceData.data.length > 0)
                return (
                  deviceData.data[0].node_alias === nodeAlias &&
                  selectedNodes[nodeAlias] === true
                );
              else return false;
            });

            nodeDataArray = {
              ...nodeDataArray,
              data: nodeDataArray.data
                .filter((f) => {
                  let date = new Date(f.ts).setSeconds(0, 0);
                  date = new Date(date).toLocaleString();
                  return allTimestamps.includes(date);
                })
                .map((v) => {
                  return { ...v, ts: new Date(v.ts).setSeconds(0, 0) };
                })
                .sort((a, b) => b.ts - a.ts),
            };

            //get red lines
            nodeDataArray.data.forEach((data, index) => {
              if (
                (data.ffi1 == 1 || data.drInd == 1 || data.depthInd == 1) &&
                !redLines.includes(index)
              )
                redLines.push(index);
            });

            setRedLineData(redLines);

            let data;
            switch (selectedDataType) {
              case "sensordepths":
                data = nodeDataArray.data
                  .sort((a, b) => a.ts - b.ts)
                  .map((data) => {
                    return {
                      y: unitType
                        ? data.depth1 + data.water_level_adj
                        : (data.depth1 + data.water_level_adj) / 12,

                      x: new Date(data.ts).toLocaleString(),
                    };
                  });
                break;
              case "depths":
                data = nodeDataArray.data
                  .sort((a, b) => a.ts - b.ts)
                  .map((data) => {
                    return {
                      y: unitType ? data.depth1 : data.depth1 / 12,
                      x: new Date(data.ts).toLocaleString(),
                    };
                  });
                break;
              case "temp":
                data = nodeDataArray.data
                  .sort((a, b) => a.ts - b.ts)
                  .map((data) => {
                    return {
                      y: unitType ? data.h2oTemp : (data.h2oTemp * 9) / 5 + 32,
                      x: new Date(data.ts).toLocaleString(),
                    };
                  });
                break;
              case "baro":
                data = nodeDataArray.data
                  .sort((a, b) => a.ts - b.ts)
                  .map((data) => {
                    console.log(
                      "Baro timestamp:",
                      data.ts,
                      new Date(data.ts).toLocaleString()
                    );

                    return {
                      y: unitType ? data.baro * 0.02952998 : data.baro,
                      x: new Date(data.ts).toLocaleString(),
                    };
                  });
                break;
              case "battery":
                data = nodeDataArray.data
                  .sort((a, b) => a.ts - b.ts)
                  .map((data) => {
                    return {
                      y: data.battery,
                      x: new Date(data.ts).toLocaleString(),
                    };
                  });
                break;
              default:
                data = [];
            }

            return {
              _meta: nodeDataArray,
              type: "line",
              label: nodeDataArray.data[0].node_alias,
              data: data,
              borderColor: colors[nodeDataArray.data[0].node_id],
              backgroundColor: colors[nodeDataArray.data[0].node_id], // Using the random color function
            };
          })
          .reverse(),
      };

      setChartData(chartData); // Assuming you have a state variable called chartData
    }
  }, [
    arrayOfDevices,
    selectedNodeId,
    selectedDataType,
    selectedNodes,
    nodeData,
    unitType,
  ]);

  const handleCheckboxChange = (event, nodeId) => {
    if (event.target.checked) {
      setSelectedNodeId([...selectedNodeId, nodeId]);
    } else {
      setSelectedNodeId(selectedNodeId.filter((id) => id !== nodeId));
    }
  };

  const calcWidth = () => {
    return layout.widgetWidth - 50;
  };

  const calcHeight = () => {
    return layout.widgetHeight - 140;
  };

  const parseTimeSpan = (timeSpan) => {
    if (timeSpan < 60) return timeSpan > 1 ? `${timeSpan} Minutes` : "1 Minute";
    else if (timeSpan / 60 < 24)
      return timeSpan / 60 > 1 ? `${timeSpan / 60} Hours` : "1 Hour";

    else return timeSpan / 60 / 24 > 1 ? `${timeSpan / 60 / 24} Days` : "1 Day";

  };

  console.log("Chart data in widget:", chartData);

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: 5,
        }}
      >
        <select
          value={selectedDataType}
          onChange={(e) => {
            setUnitType(false);
            setSelectedDataType(e.target.value);
          }}
          style={{
            padding: 10,
            backgroundColor: theme.primaryHighlight,
            color: theme.fontColor,
            border: "1px solid #ccc",
            borderRadius: 8,
            height: 40,
          }}
        >
          <option value="sensordepths">Sensor Depths</option>
          <option value="depths">Depths</option>
          <option value="temp">Temperature</option>
          <option value="baro">Barometric Pressure</option>
          <option value="battery">Battery</option>
        </select>

        {/* Select Node Checkbox */}
        <div style={{ display: "flex", flexDirection: "column" }}>
          {nodeList && (
            <DropdownSelect
              items={nodeList.map((device) => {
                return device.rawData.node_alias;
              })}
              data={selectedNodes}
              setData={setSelectedNodes}
            />
          )}
        </div>
      </div>

      {chartData && chartData.datasets.length > 0 ? (
        <MixedChart
          selectedDataType={selectedDataType}
          unitType={unitType}
          setUnitType={setUnitType}
          chartType={"awareDevice"}
          keyString={Object.keys(selectedNodes)
            .map((node) => node)
            .join("")}
          type={"bar"}
          dataSets={chartData}
          options={{
            ...example.options,
            animation: false,
            onClick: function (evt, element) {

              let dataset = 0;
              let closest = Infinity;
              const index = element[0].index;
              const mouseY = evt.y;

              element.forEach((item) => {
                if (Math.abs(mouseY - item.element.y) < closest) {
                  closest = Math.abs(mouseY - item.element.y);
                  dataset = item.datasetIndex;
                }
              });

              let selectedNode = -1;

              nodeList.forEach((node, index) => {
                if (
                  selectedNode < 0 &&
                  node.nodeId ==
                    evt.chart._sortedMetasets[dataset]._dataset._meta.nodeId
                )
                  selectedNode = index;
              });

              let pageIndex;

              simpleNodeData.forEach((item) => {
                if (
                  item.nodeId ==
                  evt.chart._sortedMetasets[dataset]._dataset._meta.nodeId
                ) {
                  item.data.forEach((datapoint, pIndex) => {
                    datapoint.timestamp_links.forEach((timestamp) => {
                      if (
                        timestamp ===
                        evt.chart._sortedMetasets[dataset]._dataset._meta.data[
                          index
                        ].created_utc
                      )
                        pageIndex = pIndex;
                    });
                  });
                }
              });

              setSelectedDevice(selectedNode);
              setPageIndex(pageIndex + 1);
              setShowCurrentDevice(true);
            },
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
                enabled: false,
              },
              customDeviceTooltip: {
                enabled: true,
              },
              verticalLinePlugin: {
                enabled: true,
                indecies: redLineData,
              },
            },
          }}
          plugins={example.plugins}
          style={{
            height: widget ? calcHeight() : window.innerHeight / 3,
            width: widget
              ? "100%"
              : window.innerWidth - 800 + (sidebarOpen ? 0 : 160),
            borderRadius: 10,
          }}
        />
      ) : (
        <div
          style={{
            height: widget ? calcHeight() : window.innerHeight / 3,
            width: widget
              ? "100%"
              : window.innerWidth - 800 + (sidebarOpen ? 0 : 160),
            borderRadius: 10,
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            color: "black",
            overflow: "auto",
            userSelect: "none",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "row", marginLeft: 15 }}
          >
            {lastDataPoints.map((point) => {
              return (
                <div
                  style={{
                    marginRight: 15,
                    width: 750, //window.innerWidth / 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (widget) {
                      navigate(
                        `/system/integrations?tab=1&device=${point.nodeId}`
                      );
                      return;
                    }

                    setSelectedDevice(point.node);
                    setPageIndex(1);
                    setShowCurrentDevice(true);
                  }}
                >
                  {/* Header information */}
                  <div style={{ textAlign: "center" }}>
                    <div>{`No data from device within ${parseTimeSpan(
                      timeSpan
                    )}`}</div>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: 20,
                      }}
                    >
                      {point.nodeAlias}
                    </div>
                    <div>{point.latestDate}</div>
                    <div>Status: Offline</div>
                  </div>

                  {/* Content */}
                  <div
                    style={{
                      marginTop: 15,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* Datapoints */}
                    <div>
                      {/* Sensor Depth */}
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            width: window.innerWidth / 50,
                            height: window.innerHeight / 40,
                            backgroundColor: "yellow",
                            marginRight: 10,
                          }}
                        />
                        <div>{`Sensor Depth: ${(
                          point.sensorDepths / 12
                        ).toFixed(3)} ft | ${point.sensorDepths.toFixed(
                          3
                        )} in`}</div>
                      </div>

                      {/* Depth */}
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            width: window.innerWidth / 50,
                            height: window.innerHeight / 40,
                            backgroundColor: "blue",
                            marginRight: 10,
                          }}
                        />
                        <div>{`Depth: ${(point.depths / 12).toFixed(
                          3
                        )} ft | ${point.depths.toFixed(3)} in`}</div>
                      </div>

                      {/* Temperature */}
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            width: window.innerWidth / 50,
                            height: window.innerHeight / 40,
                            backgroundColor: "green",
                            marginRight: 10,
                          }}
                        />
                        <div>{`Temperature: ${(
                          (point.temperature * 9) / 5 +
                          32
                        ).toFixed(3)} °F | ${point.temperature.toFixed(
                          3
                        )} °C`}</div>
                      </div>

                      {/* Barometric Pressure */}
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            width: window.innerWidth / 50,
                            height: window.innerHeight / 40,
                            backgroundColor: "purple",
                            marginRight: 10,
                          }}
                        />
                        <div>{`Pressure: ${point.baroPressure.toFixed(
                          3
                        )} mBar | ${(point.baroPressure * 0.02952998).toFixed(
                          3
                        )} inHg`}</div>
                      </div>

                      {/* Battary */}
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            width: window.innerWidth / 50,
                            height: window.innerHeight / 40,
                            backgroundColor: "rgb(121, 121, 0)",
                            marginRight: 10,
                          }}
                        />
                        <div>{`Battary: ${point.battery.toFixed(3)} V`}</div>
                      </div>

                      {/* Alert */}
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          style={{
                            width: window.innerWidth / 50,
                            height: window.innerHeight / 40,
                            backgroundColor: "Red",
                            marginRight: 10,
                          }}
                        />
                        <div>{`Alert: ${point.alert}`}</div>
                      </div>

                      <div
                        style={{
                          marginTop: 15,
                          textDecorationLine: "underline",
                          color: "blue",
                          fontStyle: "italic",
                        }}
                      >
                        View Device
                      </div>
                    </div>

                    {/* Picture */}
                    {point.pict && (
                      <div
                        style={{
                          marginLeft: 15,
                          width: window.innerHeight / 4,
                          height: window.innerHeight / 5.333333,
                          backgroundColor: "green",
                        }}
                      >
                        <img
                          style={{
                            // marginLeft: 15,
                            width: "100%",
                            height: "100%",
                          }}
                          src={point.pict}
                          alt={"Missing Img"}
                        />
                        <div
                          style={{
                            // position: "absolute",
                            marginTop: -30,
                            marginLeft: 5,
                            color: "white",
                            textShadow: "2px 2px 2px black",
                          }}
                        >
                          {extractDateTimeFromPict(point.pict).toLocaleString()}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

/*

switch (selectedDataType) {
              case "sensordepths":
                data = nodeDataArray.data
                  .sort((a, b) => a.ts - b.ts)
                  .map((data) => {

                    return {
                      y: unitType
                        ? data.depth1 + data.water_level_adj
                        : (data.depth1 + data.water_level_adj) / 12,

                      x: new Date(data.ts).toLocaleString(),
                    };
                  });
                break;
              case "depths":
                data = nodeDataArray.data
                  .sort((a, b) => a.ts - b.ts)
                  .map((data) => {
                    return {
                      y: unitType ? data.depth1 : data.depth1 / 12,
                      x: new Date(data.ts).toLocaleString(),
                    };
                  });
                break;
              case "temp":
                data = nodeDataArray.data
                  .sort((a, b) => a.ts - b.ts)
                  .map((data) => {
                    return {
                      y: unitType ? data.h2oTemp: (data.h2oTemp * 9) / 5 + 32,
                      x: new Date(data.ts).toLocaleString(),
                    };
                  });
                break;
              case "baro":
                data = nodeDataArray.data
                  .sort((a, b) => a.ts - b.ts)
                  .map((data) => {
                    console.log(
                      "Baro timestamp:",
                      data.ts,
                      new Date(data.ts).toLocaleString()
                    );

                    return {
                      y: unitType ? data.baro * 0.02952998 : data.baro,
                      x: new Date(data.ts).toLocaleString(),
                    };
                  });
                break;
              case "battery":
                data = nodeDataArray.data
                  .sort((a, b) => a.ts - b.ts)
                  .map((data) => {
                    return {
                      y: data.battery,
                      x: new Date(data.ts).toLocaleString(),
                    };
                  });
                break;
              default:
                data = [];
            }

*/
