import React, { useState, useEffect, useRef } from "react";
import { Marker, Popup, TileLayer, Tooltip } from "react-leaflet";
import ReactDOMServer from "react-dom/server";
import { Switch, Space } from "antd";
import { Checkbox } from "antd";
import { flattenPolygon } from "utils/geometryUtils";
import L from "leaflet";
import CustomAlert from "./CustomAlert";
import ZonePolygonAlerts from "./ZonePolygonAlerts";
import MapUpdater from "./MapUpdater";
import Cookies from "js-cookie";
import Massive_Unionized_MultiPolygons from "data/Massive_Unionized_MultiPolygons.json";
import WidgetMap from "components/WidgetMap";
import FullScreenMap from "components/FullScreenMap";
export default function MapPanel({
  fullScreen,
  selectedZone,
  setSelectedZone,
  selectedAlert,
  setSelectedAlert,
  alerts,
  setAlerts,
  widgetRefresh,
  preview,
  includePopup = false,
  sidebarOpen,
}) {
  const [popupOpen, setPopupOpen] = useState(false);
  const [mapCenter, setMapCenter] = useState([51.505, -0.09]);
  const [loading, setLoading] = useState(true);

  const [arrayOfZoneAlerts, setZoneAlerts] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState([0, 0]);

  
  //   "Check:",
  //   includePopup &&
  //     selectedPosition &&
  //     (selectedZone !== null || selectedAlert !== null)
  // );

  useEffect(() => {
    const arrayOfZoneCodes = [];

    if (alerts) {
      alerts.forEach((alert) => {
        if (alert.zoneCodes) {
          const arrayOfZones = JSON.parse(alert.zoneCodes);
          
          arrayOfZones.forEach((zoneCode) => {
            // check if county code is already in array
            let foundObj = arrayOfZoneCodes.find(
              (obj) => obj.zoneCode == zoneCode
            );

            //if county code is in array, add alert to it
            if (foundObj) {
              if (
                !foundObj.alerts.some(
                  (existingAlert) => existingAlert.id === alert.id
                )
              ) {
                foundObj.alerts.push(alert);
              }
            } else {
              //add either zone or county to array with new element
              arrayOfZoneCodes.push({
                zoneCode: zoneCode,
                alerts: [alert],
              });
            }
          });
        }
      });
    }
    setZoneAlerts(arrayOfZoneCodes);
  }, [alerts]);

  useEffect(() => {
    setMapCenter([Cookies.get("latitude"), Cookies.get("longitude")]);
    setLoading(false);
  }, [widgetRefresh]);

  useEffect(() => {
    setPopupOpen(true);
  }, [selectedZone, selectedAlert]);

  const createCustomIcon = () => {
    const alert = selectedZone?.alerts[0] || selectedAlert;

    

    if (alert)
      return L.divIcon({
        html: ReactDOMServer.renderToString(
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: 10,
              minHeight: "100px",
              width: 400,
              backgroundColor: "white",
              color: "black",
              boxSizing: "border-box",
            }}
          >
            <div style={{ fontWeight: "bold" }}>{alert?.type}</div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ marginRight: 5 }}>Message:</div>
              {alert?.description}
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ marginRight: 5, fontWeight: "bold" }}>
                Start Time:
              </div>
              {alert?.startTime}
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ marginRight: 5, fontWeight: "bold" }}>
                Expire Time:
              </div>
              {alert?.endTime}
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div style={{ marginRight: 5, fontWeight: "bold" }}>
                Issuing Office:
              </div>
              {alert?.sender}
            </div>
            {selectedZone && (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ marginRight: 5, fontWeight: "bold" }}>
                  Location:
                </div>
                {selectedZone.zoneCode}
              </div>
            )}
          </div>
        ),
      });
  };

  const emptyCustomIcon = () => {
    return L.icon({
      iconUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
      shadowUrl:
        "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowSize: [41, 41],
    });
  };

  if (fullScreen)
    return (
      <div style={{ height: "100%" }}>
        {!loading && (
          <FullScreenMap
            key={"fullScreenMap"}
            mapCenter={mapCenter}
            zoom={9}
            // satelliteToggleStyle={{ bottom: "25px", left: "30px" }}
          >
            {arrayOfZoneAlerts.map((zoneCodeOBJ, index) => {
              return (
                <div key={index}>
                  <ZonePolygonAlerts
                    zoneCodeOBJ={zoneCodeOBJ}
                    setSelectedZone={setSelectedZone}
                    setSelectedAlert={setSelectedAlert}
                    setMapCenter={setMapCenter}
                    setSelectedPosition={setSelectedPosition}
                  />
                </div>
              );
            })}

            {alerts &&
              alerts.length > 0 &&
              alerts.map((alert, index) => {
                if (alert.provider === "Fusion") {
                  return (
                    <div key={index}>
                      <CustomAlert
                        alert={alert}
                        setSelectedAlert={setSelectedAlert}
                        setSelectedZone={setSelectedZone}
                        setMapCenter={setMapCenter}
                        setSelectedPosition={setSelectedPosition}
                      />
                    </div>
                  );
                }
              })}

            {selectedAlert && selectedAlert.polygon && !selectedZone && (
              <MapUpdater
                positions={JSON.parse(selectedAlert.polygon).map(
                  ([lng, lat]) => [lng, lat]
                )}
              />
            )}

            {selectedZone && (
              <MapUpdater
                positions={(() => {
                  
                  const response = flattenPolygon(
                    Massive_Unionized_MultiPolygons[selectedZone?.zoneCode][0]
                  );
                  const swappedPolygon = response.map((coord) => [
                    coord[1],
                    coord[0],
                  ]);

                  return swappedPolygon;
                })()}
              />
            )}
          </FullScreenMap>
        )}
      </div>
    );
  else
    return (
      <div>
        {!loading && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <div style={{ position: "relative" }}>
              <WidgetMap
                sidebarOpen={sidebarOpen}
                mapCenter={mapCenter}
                zoom={9}
                preview={preview}
              >
                {arrayOfZoneAlerts.map((zoneCodeOBJ, index) => {
                  return (
                    <div key={index}>
                      <ZonePolygonAlerts
                        zoneCodeOBJ={zoneCodeOBJ}
                        setSelectedZone={setSelectedZone}
                        setSelectedAlert={setSelectedAlert}
                        setMapCenter={setMapCenter}
                        setSelectedPosition={setSelectedPosition}
                      />
                    </div>
                  );
                })}

                {alerts &&
                  alerts.length > 0 &&
                  alerts.map((alert, index) => {
                    if (alert.provider === "Fusion") {
                      return (
                        <div key={index}>
                          <CustomAlert
                            alert={alert}
                            setSelectedAlert={setSelectedAlert}
                            setSelectedZone={setSelectedZone}
                            setMapCenter={setMapCenter}
                            setSelectedPosition={setSelectedPosition}
                          />
                        </div>
                      );
                    }
                  })}

                {selectedAlert && selectedAlert.polygon && !selectedZone && (
                  <MapUpdater
                    positions={JSON.parse(selectedAlert.polygon).map(
                      ([lng, lat]) => [lng, lat]
                    )}
                  />
                )}

                {selectedZone && (
                  <MapUpdater
                    positions={(() => {
                      
                      const response = flattenPolygon(
                        Massive_Unionized_MultiPolygons[
                          selectedZone?.zoneCode
                        ][0]
                      );
                      const swappedPolygon = response.map((coord) => [
                        coord[1],
                        coord[0],
                      ]);

                      return swappedPolygon;
                    })()}
                  />
                )}

                {includePopup &&
                  selectedPosition &&
                  (selectedZone !== null || selectedAlert !== null) && (
                    <>
                      {popupOpen && (
                        <Marker
                          position={[selectedPosition[1], selectedPosition[0]]}
                          icon={
                            popupOpen ? createCustomIcon() : emptyCustomIcon()
                          }
                          eventHandlers={{
                            click: () => {
                              setPopupOpen(false);
                            },
                          }}
                        />
                      )}
                      {!popupOpen && (
                        <Marker
                          position={[selectedPosition[1], selectedPosition[0]]}
                          eventHandlers={{
                            click: () => {
                              setPopupOpen(true);
                            },
                          }}
                        />
                      )}
                    </>
                  )}
              </WidgetMap>
            </div>
          </div>
        )}
      </div>
    );
}
