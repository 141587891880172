import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import {
  FaArrowRight,
  FaHome,
  FaPen,
  FaSearch,
  FaStar,
  FaTrash,
} from "react-icons/fa";
import { FaLocationDot, FaMagnifyingGlass } from "react-icons/fa6";
import PlacesSearch from "./PlacesSearch";
import * as api from "apis/FusionAPI";
import { fromLatLng } from "react-geocode";
import Popup from "reactjs-popup";
import { BsChevronDown } from "react-icons/bs";
import useDebounce from "hooks/useDebounce";
import { useNavigate } from "react-router-dom";
import { FiHome } from "react-icons/fi";
// import { testPermission } from "data/routeData";
import ConfirmPopup from "./UI/ConfirmPopup";
import { useAPIData } from "hooks/useAPIData";
import { usePerms } from "hooks/usePerms";
import useDataRefresh from "hooks/useDataRefresh";
import { ColorContext } from "App";

let eventSource;

const PlacesSelector = ({ widgetRefresh, setWidgetRefresh }) => {
  const navigate = useNavigate();
  const [places, setPlaces] = useState([]);
  const [location, setLocation] = useState({});
  const [searching, setSearching] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [showWaitingForData, setShowWaitingForData] = useState(false);
  const [waitingForDataMessage, setWaitingForDataMessage] =
    useState("Loading...");

  const [widgetRefreshKey, setWidgetRefreshKey] = useState(0);
  const debouncedWidgetRefresh = useDebounce(widgetRefreshKey, 1000);
  const FusionData = useAPIData();

  const perms = usePerms();
  const dataRefresh = useDataRefresh();

  useEffect(() => {
    if (debouncedWidgetRefresh) {
      (async () => {
        
        setWidgetRefresh(debouncedWidgetRefresh);
        dataRefresh.setWidgetRefresh(debouncedWidgetRefresh);
      })();
    }
  }, [debouncedWidgetRefresh]);

  useEffect(() => {
    // Cookies.set('city', 'Pensacola' )
    // Cookies.set('state', 'Florida' )
    // Cookies.set('latitude', 30.4213 )
    // Cookies.set('longitude', -87.2169)
    (async () => {
      const response = await api.getUserPlacesData();
      
      const placeList = response.data.places || [];
      setPlaces(placeList);
      if (Cookies.get("selectedLocationIndex") > placeList.length - 1) {
        Cookies.set("selectedLocationIndex", 0);
      }
      
      if (!Cookies.get("selectedLocationIndex"))
        Cookies.set("selectedLocationIndex", 0);

      // setLocation(placeList[Cookies.get('selectedLocationIndex')])

      if (placeList.length === 0) {
        addDefaultLocationIfEmpty();
      } else {
        selectLocation(placeList[Cookies.get("selectedLocationIndex")]);
      }
    })();
  }, []);

  const selectLocation = (location) => {
    // if (places.length === 0) return
    if (!location) return;

    if (eventSource) eventSource.close();

    api.informLocationChange(location);
    
    setWidgetRefreshKey(new Date().toString());
    
    const locationCheck = FusionData.isLocationReady({
      location: location,
      fetchCallback: (message) => {
        setShowWaitingForData(true);
        setWaitingForDataMessage(message);
      },
      errorCallback: (message) => {
        setShowWaitingForData(true);
        setWaitingForDataMessage(message);
      },
      successCallback: (message) => {
        setShowWaitingForData(false);
        setWidgetRefreshKey(new Date().toString());
        setLocation(location);
      },
    });
    if (locationCheck) eventSource = locationCheck.eventSource;
  };

  const removeLocation = (location) => {
    if (places.length === 1) return;
    
    let tempPlaces = [...places];
    let index = tempPlaces.indexOf(location);
    if (index > -1) {
      tempPlaces.splice(index, 1);
    }
    setPlaces([...tempPlaces]);
    updateUserPlacesData([...tempPlaces]);
    Cookies.set("selectedLocationIndex", 0);
    selectLocation(tempPlaces[0]);
  };

  const addLocation = (location) => {
    if (places.find((place) => place.place_id === location.place_id)) return;
    
    let tempPlaces = [...places];
    tempPlaces.push(location);
    tempPlaces.sort((a, b) => {
      if (a.DefaultLocationUserHome === true) return -1;
      if (b.DefaultLocationUserHome === true) return 1;
      if (a.title > b.title) return 1;
      if (a.title < b.title) return -1;
      return 0;
    });
    setPlaces([...tempPlaces]);
    updateUserPlacesData([...tempPlaces]);
    const index = tempPlaces.indexOf(location);
    Cookies.set("selectedLocationIndex", index);
    selectLocation(location);
  };

  const changeHomeLocation = (location) => {
    let tempPlaces = [...places];
    tempPlaces = tempPlaces.map((place) => {
      place.DefaultLocationUserHome = false;
      return place;
    });
    let index = tempPlaces.indexOf(location);
    if (index > -1) {
      tempPlaces[index].DefaultLocationUserHome = true;
    }
    tempPlaces = tempPlaces.sort((a, b) => {
      if (a.DefaultLocationUserHome === true) return -1;
      if (b.DefaultLocationUserHome === true) return 1;
      if (a.title > b.title) return 1;
      if (a.title < b.title) return -1;
      return 0;
    });

    setPlaces([...tempPlaces]);
    updateUserPlacesData([...tempPlaces]);
    selectLocation(tempPlaces[0]);
    Cookies.set("selectedLocationIndex", 0);
  };

  const addDefaultLocationIfEmpty = () => {
    let defaultPlace = {
      city: "Dallas",
      DefaultLocationUserHome: false,
      term: "Dallas",
      type: "locality",
      title: "Dallas",
      geometry: {
        location: {
          lat: 32.7766642,
          lng: -96.7969879,
        },
        viewport: {
          northeast: {
            lat: 33.0237920649461,
            lng: -96.46375805411309,
          },
          southwest: {
            lat: 32.6175369971454,
            lng: -96.9993469629029,
          },
        },
      },
      latitude: 32.7766642,
      place_id: "ChIJS5dFe_cZTIYRj2dH9qSb7Lk",
      longitude: -96.7969879,
      place_data: {
        name: "Dallas",
        types: ["locality", "political"],
        geometry: {
          location: {
            lat: 32.7766642,
            lng: -96.7969879,
          },
          viewport: {
            northeast: {
              lat: 33.0237920649461,
              lng: -96.46375805411309,
            },
            southwest: {
              lat: 32.6175369971454,
              lng: -96.9993469629029,
            },
          },
        },
        formatted_address: "Dallas, TX, USA",
        address_components: [
          {
            types: ["locality", "political"],
            long_name: "Dallas",
            short_name: "Dallas",
          },
          {
            types: ["administrative_area_level_2", "political"],
            long_name: "Dallas County",
            short_name: "Dallas County",
          },
          {
            types: ["administrative_area_level_1", "political"],
            long_name: "Texas",
            short_name: "TX",
          },
          {
            types: ["country", "political"],
            long_name: "United States",
            short_name: "US",
          },
        ],
      },
      state_long: "Texas",
      state_short: "TX",
    };
    if (places.length === 0) {
      setPlaces([defaultPlace]);
      updateUserPlacesData([defaultPlace]);
      setLocation(defaultPlace);
    }
  };

  async function updateUserPlacesData(places) {
    const res = await api.updateUserPlacesData(places);
  }

  const theme = useContext(ColorContext);
  
  if (perms.get())
    return (
      <div>
        {/* <div
        onClick={() => {
          updateUserPlacesData(null)
          setPlaces(null)
          setLocation(null)
          
          Cookies.remove('city')
          Cookies.remove('state')
          Cookies.remove('latitude')
          Cookies.remove('longitude')
        }}
      >DELETE ALL</div> */}
        {!searching && location && (
          <Popup
            on={"click"}
            trigger={
              <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                {location !== places[0] && (
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => selectLocation(places[0])}
                  >
                    <FaHome />
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    backgroundColor: theme.primaryHighlight,
                    borderRadius: 30,
                    // minWidth: 20,
                    padding: 5,
                    paddingLeft: 10,
                    paddingRight: 10,
                    fontSize: 18,
                    color: theme.fontColor,
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        background: theme.background,
                        width: 24,
                        height: 24,
                        borderRadius: 24,
                        alignContent: "center",
                      }}
                    >
                      <div style={{ marginLeft: 5 }}>
                        {location.DefaultLocationUserHome ? (
                          <FaHome size={14} />
                        ) : (
                          <FaLocationDot size={14} />
                        )}{" "}
                      </div>
                    </div>
                    <Popup 
                      trigger={
                    <div
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        width: 100,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {location.title}
                    </div>}
                    on={'hover'}
                    position={'top center'}
                    closeOnDocumentClick
                    closeOnEscape
                    >
                      <div style={{padding: 10, paddingTop: 5, paddingBottom: 5, backgroundColor: 'white', color: 'black', borderRadius: 5}}>
                        {location.title}
                      </div>
                    </Popup>
                  </div>
                  <div style={{ display: "flex", gap: 10 }}>
                    {perms.testPermission(
                      [8, 10, 11, 12, 13],
                      ["Create-EditPlaces"]
                    ) && (
                      <div
                        onClick={() => {
                          navigate("/usermenu?tab=1");
                        }}
                      >
                        <FaPen />
                      </div>
                    )}
                    <div
                      onClick={() => {
                        setSearching(true);
                        setDropDownOpen(false);
                      }}
                    >
                      <FaSearch />
                    </div>
                    {places.length > 1 && <BsChevronDown />}
                  </div>
                </div>
              </div>
            }
            open={dropDownOpen}
            onOpen={() => setDropDownOpen(true)}
            onClose={() => setDropDownOpen(false)}
            position={"bottom center"}
            closeOnDocumentClick
            closeOnEscape
          >
            {places.length > 1 && (
              <div style={{ height: 200, overflowY: "auto", borderRadius: 10 }}>
                {places &&
                  places.length > 0 &&
                  places.map((place, index) => (
                    <div
                      key={index}
                      value={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        backgroundColor: theme.primaryHighlight,
                        width: 300,
                        padding: 5,
                        borderBottom:
                          index !== places.length - 1 && "1px solid white",
                        color: theme.fontColor,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        selectLocation(place);
                        setDropDownOpen(false);
                        Cookies.set("selectedLocationIndex", index);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          marginLeft: 5,
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <div>{place.title}</div>
                        {!place.DefaultLocationUserHome &&
                          perms.testPermission(
                            [8, 10, 11, 12, 13],
                            ["HomeLocationEditable"]
                          ) && (
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                changeHomeLocation(place);
                              }}
                            >
                              <FiHome />
                            </div>
                          )}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </Popup>
        )}
        {searching && (
          <div>
            <PlacesSearch
              places={places}
              addLocation={addLocation}
              setSearching={setSearching}
              selectLocation={selectLocation}
              setWidgetRefresh={setWidgetRefresh}
              quickLook
            />
          </div>
        )}
        {showWaitingForData && (
          <ConfirmPopup
            open={showWaitingForData}
            setOpen={setShowWaitingForData}
            message={waitingForDataMessage}
            options={["Cancel"]}
            callbacks={[
              () => {
                setShowWaitingForData(false);
                FusionData.cancelLocationInquiry();
              },
            ]}
            closeAction={() => {
              setShowWaitingForData(false);
              FusionData.cancelLocationInquiry();
            }}
            buttonStyles={[{ backgroundColor: theme.background, color: theme.fontColor }]}
          />
        )}
      </div>
    );
};

export default PlacesSelector;
