import useDebounce from 'hooks/useDebounce';
import React, { useContext, useEffect, useState } from 'react'
import * as api from "apis/FusionAPI";
import { FaArrowLeft, FaSave } from 'react-icons/fa';
import Popup from 'reactjs-popup';
import Cookies from 'js-cookie';
import ConfirmPopup from './UI/ConfirmPopup';
import { useAPIData } from 'hooks/useAPIData';
import { usePerms } from 'hooks/usePerms';
import useDataRefresh from 'hooks/useDataRefresh';
import { ColorContext } from 'App';

let eventSource;
const PlacesSearch = ( { places, addLocation, setSearching, noBack, setMapCenter, quickSave, quickLook, setWidgetRefresh } ) => {

  const [searchResults, setSearchResults] = useState([])
  const [query, setQuery] = useState('')
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [tempLocation, setTempLocation] = useState(null)
  const [fetching, setFetching] = useState(false)
  const dataRefresh = useDataRefresh()

  const [showWaitingForData, setShowWaitingForData] = useState(false);
  const [waitingForDataMessage, setWaitingForDataMessage] = useState("Loading...");
  const debouncedSearchTerm = useDebounce(query, 1000);
  const FusionData = useAPIData();
  const perms = usePerms()
  useEffect(() => {
    if (debouncedSearchTerm) {
      (async () => {
        
        searchAutoFill(debouncedSearchTerm);
      })();
    }
  }, [debouncedSearchTerm]);

  
  const theme = useContext(ColorContext);

  const searchAutoFill = async (query) => {
    let searchLocation =  query.trim()
    if (searchLocation === "") {
      setSearchResults([])
      return;
    }
    setFetching(true)
    setSearchResults([])
  
    try {
      let results = await api.autoCompleteSearch(Cookies.get("latitude"), Cookies.get("longitude"), searchLocation)
      results = results.data.result
      
      if (results) {
        setSearchResults(results)
        setFetching(false)
      }
      else {
        setFetching(false)
        setSearchResults([])
      } 
    } catch (error) {
      console.error("Error fetching autocomplete data:", error);
      setFetching(false)
      setSearchResults([])
    }
  }

  const selectLocation = (location) => {
    // if (places.length === 0) return
    if (!location) return;

    if (eventSource) eventSource.close()

    
    
    const locationCheck = FusionData.isLocationReady({
      location: location,
      fetchCallback: (message) => {
        setShowWaitingForData(true);
        setWaitingForDataMessage(message);
      },
      errorCallback: (message) => {
        setShowWaitingForData(true);
        setWaitingForDataMessage(message);
      },
      successCallback: (message) => {
        setShowWaitingForData(false);
        setWidgetRefresh(new Date().toString());
        dataRefresh.setWidgetRefresh(new Date().toString());
        setTempLocation(location);
      }
  
    });
    if (locationCheck?.eventSource) eventSource = locationCheck.eventSource;

  };

 

  const tempLookAtLocation = (location) => {
    selectLocation(location)
    // setTempLocation(location)
    
    // Cookies.set('city', location.city )
    // Cookies.set('state', location.state_long )
    // Cookies.set('latitude', location.latitude )
    // Cookies.set('longitude', location.longitude)
    // setQuery(location.place_data.formatted_address)
    // if (setMapCenter) setMapCenter([location.latitude, location.longitude])
    // setWidgetRefresh(new Date().toString())
  }

  const saveLocation = (location) => {
    if (!location) return
    addLocation(location)
    setSearching(false)
  }

  return (
    <div >
      <div style={{display: "flex", flexDirection: "row"}}>
        {/* <input 
          type="text" 
          placeholder="Search for a location" 
          value={query} 
          style={{
            backgroundColor: theme.base,
            color: theme.fontColor,
            borderRadius: 5,
            padding: 5,
            marginLeft: 5
          
          }}
          onChange={(e) => {setQuery(e.target.value)}}
        /> */}
        <Popup
          // on={"click"}
          trigger={
            <div 
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: 'space-between',
              backgroundColor: theme.primaryHighlight,
              borderRadius: 30,
              minWidth: 200,
              padding: 5,
              paddingLeft: 10,
              paddingRight: 10,
              fontSize: 18,
              color: theme.fontColor,
            }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setDropDownOpen(true)
            }}
            
            >
              
        {!noBack &&<button onClick={() => setSearching(false)}>
          <FaArrowLeft />
        </button>}
            <input 
              style={{
                width:"100%",
                marginLeft: 10,
              }}
              type="text" 
              placeholder="Search for a location..." 
              value={query} 
              onClick={(e) => {
                
                e.preventDefault()
                e.stopPropagation()
                setDropDownOpen(true)
              }}
              onChange={(e) => {setQuery(e.target.value)}}
            />
            {perms.testPermission([8, 10, 11, 12, 13], ["Create-EditPlaces"]) && tempLocation && !quickSave &&<div onClick={() => saveLocation(tempLocation)} style={{cursor:'pointer'}}>
              <FaSave/>
            </div>}
            </div>
          }
          open={dropDownOpen}
          onOpen={() => setDropDownOpen(true)}
          onClose={() => setDropDownOpen(false)}
          position={"bottom center"}
          closeOnDocumentClick
          closeOnEscape
        >
          {dropDownOpen && <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: theme.primary,
              borderRadius: 5,
              width: 200,
              padding: 5,
              color: theme.fontColor,
            }}
          >
            {searchResults.length === 0 && fetching && <div style={{padding: 5}}>Loading...</div>}
            {searchResults.length === 0 && !fetching && <div style={{padding: 5}}>No results found</div>}
            {searchResults.map((item, index) => {
              return (
                <button
                  key={index}
                  style={{ 
                    padding: 5,
                    borderBottom: index === searchResults.length - 1 ? "none" : "1px solid white",
                  }}
                  onClick={() => {
                    
                    setDropDownOpen(false);
                    if (quickLook) tempLookAtLocation(searchResults[index])
                    if (quickSave) addLocation(searchResults[index])
                  }}
                >
                  {item.description || "none"}
                  {/* {item.place_data.formatted_address} */}
                  {/* {item.city}{item.state_short !== "" && ", "} {item.state_short} */}
                </button>
              );
            })}
          </div>}
        </Popup>
        {/* <button>Search</button> */}
      </div>
      {showWaitingForData && (
          <ConfirmPopup
            open={showWaitingForData}
            setOpen={setShowWaitingForData}
            message={waitingForDataMessage}
            options={["Cancel"]}
            callbacks={[
              () => {
                setShowWaitingForData(false);
                FusionData.cancelLocationInquiry()
              },
            ]}
            closeAction={() => {
              setShowWaitingForData(false);
              FusionData.cancelLocationInquiry()
            }}
            buttonStyles={[{ backgroundColor: theme.background, color: theme.fontColor }]}
          />
          // <div style={{background:'white', color:'black', position: 'absolute'}}>
          //   {waitingForDataMessage}
          //   <div onClick={() => {
          //     setShowWaitingForData(false)
          //     eventSource.close()
          //   }}>Close</div>
          //   </div>
        )}
      {/* <div
        onClick={() => {
          generateDefaultLocation(
            // Cookies.get("latitude"),
            // Cookies.get("longitude"),
            // Cookies.get("city") + ", " + Cookies.get("state")
          )
        }}
      >
        Generate Default Location!
      </div> */}
      {/* {searchResults.length > 0 && <select
        style={{backgroundColor: theme.base}}
        onChange={(e) => {
          if (e.target.value === 'search') return
          addLocation(searchResults[e.target.value])
          setSearching(false)
        }}
      >
        <option value="search">- Add a result -</option>
        {searchResults.map((place, index) => {
          return <option key={index} value={index}>{place.city}, {place.state}</option>
        })}
      </select>} */}
    </div> 
  )
}

export default PlacesSearch