import { useMapEvents } from "react-leaflet";
import * as d3 from "d3-geo";

export default function CreatePolygon({ alertData, setAlertData }) {
  function reeval(alertData, newCoords) {
    if (alertData.coordinates.length >= 3) {
      // find new coord placed position
      let newPin = [newCoords.lng, newCoords.lat]; // d3.geoDistance expects [longitude, latitude]

      return PointToEdgeDistance(alertData.coordinates, newPin);

      // find 2 closest pins

      // loop through alertData.coordinates to find distance between all pins
      let distances = alertData.coordinates.map((coord) => {
        //get distance between coord and newCoords
        const flippedCoords = [coord[1], coord[0]];

        let foundDist = d3.geoDistance(flippedCoords, newPin);
        return { coord, foundDist };
      });

      // sort distances array by foundDist
      distances.sort((a, b) => a.foundDist - b.foundDist);

      // find 2 closest pins array index (a, b)
      let closestPinA = distances[0].coord;
      let closestPinB = distances[1].coord;

      // find the indices of the closest pins in the original coordinates array
      let indexA = alertData.coordinates.findIndex(
        (coord) => coord[0] === closestPinA[0] && coord[1] === closestPinA[1]
      );
      let indexB = alertData.coordinates.findIndex(
        (coord) => coord[0] === closestPinB[0] && coord[1] === closestPinB[1]
      );

      

      //  insert

      // determine the correct index to insert the new pin
      let insertIndex = Math.min(indexA, indexB) + 1;

      // create a copy of the coordinates array
      let newCoordsArray = [...alertData.coordinates];
      

      if (
        Math.min(indexA, indexB) === 0 &&
        Math.max(indexA, indexB) === alertData.coordinates.length - 1
      ) {
        insertIndex = alertData.coordinates.length;
      }

      //problem when inserting between 0 and end
      // if (insertIndex === 1 && (indexA === alertData.coordinates.length || indexB === alertData.coordinates.length)){
      //   insertIndex = alertData.coordinates.length
      // }

      // splice new pin into coordinates array at the correct position
      newCoordsArray.splice(insertIndex, 0, newCoords);

      return newCoordsArray;
    } else {
      return [...alertData.coordinates, [newCoords.lat, newCoords.lng]];
    }
  }

  //Test functions
  function InsertToClosestEdge(polygon, newPoint) {
    

    let closestEdgeIndex = -1;
    let closestDistance = Infinity;

    for (let i = 0; i < polygon.length; i++) {
      const nextIndex = (i + 1) % polygon.length;

      
      const distance = PointToEdgeDistance(
        newPoint,
        polygon[i],
        polygon[nextIndex]
      );

      if (distance < closestDistance) {
        
        
        closestDistance = distance;
        closestEdgeIndex = i;
      }
    }

    polygon.splice(closestEdgeIndex + 1, 0, newPoint);
    return { coords: [...polygon], inserted: closestEdgeIndex + 1 };
  }

  function PointToEdgeDistance(point, edgeStart, edgeEnd) {
    if (!checkProjection(point, edgeStart, edgeEnd)) return Infinity;

    const result =
      Math.abs(
        (edgeEnd[1] - edgeStart[1]) * point[0] -
          (edgeEnd[0] - edgeStart[0]) * point[1] +
          edgeEnd[0] * edgeStart[1] -
          edgeEnd[1] * edgeStart[0]
      ) /
      Math.sqrt(
        (edgeEnd[1] - edgeStart[1]) ** 2 + (edgeEnd[0] - edgeStart[0]) ** 2
      );

    

    return result;
  }

  function checkProjection(point, edgeStart, edgeEnd) {
    let l2 =
      (edgeEnd[0] - edgeStart[0]) ** 2 + (edgeEnd[1] - edgeStart[1]) ** 2;
    // if (l2 === 0) return distanceToPoint(point, edgeStart); // edgeStart and edgeEnd are the same point

    let t =
      ((point[0] - edgeStart[0]) * (edgeEnd[0] - edgeStart[0]) +
        (point[1] - edgeStart[1]) * (edgeEnd[1] - edgeStart[1])) /
      l2;

    return t >= 0 && t <= 1;
  }

  function getConvexHull(points) {
    if (points.length <= 2) return points;

    points.sort((a, b) => a[0] - b[0] || a[1] - b[1]);

    const crossProduct = (o, a, b) =>
      (a[0] - o[0]) * (b[1] - o[1]) - (a[1] - o[1]) * (b[0] - o[0]);

    const lower = [];
    for (let i = 0; i < points.length; i++) {
      while (
        lower.length >= 2 &&
        crossProduct(
          lower[lower.length - 2],
          lower[lower.length - 1],
          points[i]
        ) <= 0
      ) {
        lower.pop();
      }
      lower.push(points[i]);
    }

    const upper = [];
    for (let i = points.length - 1; i >= 0; i--) {
      while (
        upper.length >= 2 &&
        crossProduct(
          upper[upper.length - 2],
          upper[upper.length - 1],
          points[i]
        ) <= 0
      ) {
        upper.pop();
      }
      upper.push(points[i]);
    }

    upper.pop();
    lower.pop();
    return lower.concat(upper);
  }

  // function findInsertionIndex(points, newPoint) {
  //   // Calculate the distance between two points
  //   const distance = (point1, point2) => {
  //     return Math.sqrt((point1.x - point2.x) ** 2 + (point1.y - point2.y) ** 2);
  //   };

  //   let bestIndex = 0;
  //   let smallestIncrease = Infinity;

  //   // Iterate through each pair of points to find the best insertion point
  //   for (let i = 0; i < points.length; i++) {
  //     const currentPoint = points[i];
  //     const nextPoint = points[(i + 1) % points.length]; // Wrap around to the start for the last point

  //     // Calculate the increase in perimeter if the new point were added between currentPoint and nextPoint
  //     const increase =
  //       distance(currentPoint, newPoint) +
  //       distance(newPoint, nextPoint) -
  //       distance(currentPoint, nextPoint);

  //     // Update the bestIndex if this position results in a smaller increase
  //     if (increase < smallestIncrease) {
  //       bestIndex = i + 1; // +1 to insert after the current point
  //       smallestIncrease = increase;
  //     }
  //   }

  //   return bestIndex;
  // }

  // function insertPointInShape(points, newPoint) {
  //   const index = findInsertionIndex(points, newPoint);
  //   points.splice(index, 0, newPoint); // Insert newPoint at the calculated position
  //   return points;
  // }

  useMapEvents({
    click: (e) => {
      let arrayOfCoords;
      if (alertData.coordinates.length >= 2) {
        const temp = InsertToClosestEdge(
          [...alertData.coordinates],
          [e.latlng.lat, e.latlng.lng]
        );

        arrayOfCoords = {
          coords: [...temp.coords],
          inserted: [...alertData.lastInserted, [e.latlng.lat, e.latlng.lng]],
        };
      } else {
        arrayOfCoords = {
          coords: [...alertData.coordinates, [e.latlng.lat, e.latlng.lng]],
          inserted: alertData.lastInserted
            ? [...alertData.lastInserted, [e.latlng.lat, e.latlng.lng]]
            : [[e.latlng.lat, e.latlng.lng]],
        };
      }

      

      setAlertData({
        ...alertData,
        // coordinates: [...alertData.coordinates, [[e.latlng.lat], [e.latlng.lng]]],
        coordinates: [...arrayOfCoords.coords],
        lastInserted: arrayOfCoords.inserted,
      });
    },
  });
  return null;
}

// e.latlng.lat and e.latlng.lng
