import React, { useContext, useEffect, useState } from "react";
import TabsHeader from "../../components/TabsHeader";
import ConnectedSystems from "./ConnectedSystems";
import Devices from "./Devices";
import * as api from "apis/FusionAPI";
import { ColorContext } from "App";

// function Tab({ tabId, currentTab, setTab, children }) {
//   return (
//     <button
//       style={{
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         flex: 1,
//         height: 31,
//       }}
//       onClick={() => setTab(tabId)}
//     >
//       <div
//         style={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "center",
//           width: "95%",
//           height: 27,
//           borderBottom:
//             currentTab === tabId
//               ? "rgba(255, 255, 255, 1) solid 3px"
//               : "rgba(255, 255, 255, 0) solid 3px",
//         }}
//       >
//         {children}
//       </div>
//     </button>
//   );
// }

export default function Connections() {
  const [tabs, setTabs] = useState([1, 0]);
  
  const theme = useContext(ColorContext);

  const RenderTab = () => {
    switch (tabs[0]) {
      case 0:
        return <ConnectedSystems />;
      case 1:
        return <Devices />;
      default:
        return <div />;
    }
  };

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      {/* header */}
      <TabsHeader
        onTabChange={(newTabArr) => {
          setTabs([...newTabArr]);
        }}
        currentTabs={tabs}
        options={[
          //1st depth
          { option: "Connected Systems" },
          { option: "Connected Devices" },
        ]}
        styles={[{}, { backgroundColor: "#436c98" }]}
      />

      {/* content */}
      <div
        style={{
          height: "calc(100% - 44px)",
          display: "flex",
          flexDirection: "column",
          // backgroundColor: theme.primaryShadow
        }}
      >
        {RenderTab()}
      </div>
    </div>
  );
}
