import * as api from "apis/FusionAPI";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

let data;
let isFetching = false;
let accessLevel;

export const usePerms = () => {
  const [sData, setSData] = useState(data);

  useEffect(() => {
    if (Cookies.get("accessToken")) fetchData();
  }, []);

  useEffect(() => {
    setSData(data);
  }, [data]);

  const fetchData = async () => {
    if (!accessLevel) {
      const accessResponse = await api.getAccessLevel();
      
      accessLevel = accessResponse.data.result.group_id;
    }
    if (isFetching) {
      const interval = setInterval(() => {
        if (!isFetching) {
          clearInterval(interval);
          return sData;
        }
      }, 1000);
      setTimeout(() => {
        clearInterval(interval);
        isFetching = false;
        return sData;
      }, 10000);
    } else {
      if (data) {
        setSData(data);
        return;
      }
      isFetching = true;
      const response = await api.getUserPerms();
      if (response.status === 200) {
        data = JSON.parse(response.data.perms);
        setSData(data);
      }
      isFetching = false;
    }
  };

  const get = () => {
    return data;
  };

  const setAccessLevel = (level) => {
    accessLevel = level;
  };

  function testPermission(accessLevels, permissions = [], testData = null) {
    if (testData) {
      console.log("Test Data:", testData);
    }
    const accessLevelCheck = accessLevels.includes(parseInt(accessLevel));
    let permissionsCheck;
    let permMissingCheck;

    if (permissions.length > 0) {
      const permArr = permissions.map((permission) => {
        if (testData) {
          return testData[permission];
        }
        return sData ? sData[permission] : false;
      });
      if (permArr.includes(undefined) || permArr.includes(null)) {
        return false;
      } //maybe this will allow missing keys
      permissionsCheck = !permArr.includes(false);
    } else {
      permissionsCheck = true;
    }
    return accessLevelCheck && permissionsCheck;
  }

  const validatePermissions = async (perms) => {
    try {
      const response = await api.validateRoutePerms(perms);
      return true;
    } catch (error) {
      if (error.response.status === 403) {
        return false;
      } else {
        return true;
      }
    }
  };

  const reset = () => {
    data = null;
    isFetching = false;
    accessLevel = null;
  };

  return {
    get,
    setAccessLevel,
    testPermission,
    validatePermissions,
    isFetching,
    sData,
    reset,
    accessLevel,
  };
};
